import TYPES from '../types/exame';

export const setParametrosPesquisa = (parametros) => {
    return {
        type: TYPES.SET_FILTROS,
        payload: { parametros }
    };
}

export const reset = () => {
    return {
        type: TYPES.RESET
    };
}

export const setParametrosPesquisaLG = (parametros) => {
    return {
        type: TYPES.SET_FILTROS_LG,
        payload: { parametros }
    };
}

export const resetLG = () => {
    return {
        type: TYPES.RESET_LG
    };
}

export const setAtualizarBusca = (atualizar) => {
    return {
        type: TYPES.ATUALIZAR_BUSCA,
        payload: { atualizar: atualizar }
    };
}