export const INVESTIGACAO_DOR = 'investigacaoDor';
export const DOR_MOMENTO_EXAME = 'dorMomentoExame';
export const DATA_ULTIMO_EPISODIO = 'dataUltimoEpisodio';
export const DURACAO_EPISODIO = 'duracaoEpisodio';
export const DURACAO_ULTIMO_EPISODIO = 'duracaoUltimoEpisodio';
export const TEMPO_APRESENTA_SINTOMA = 'tempoApresentaSintoma';
export const ESPECIFICACAO_TEMPO_APRESENTA_SINTOMA = 'especificacaoTempoApresentaSintoma';
export const LOCALIZACAO_IRRADIACAO_DOR = 'localizacaoIrradiacaoDor';
export const SINTOMAS_ASSOCIADOS = 'sintomasAssociados';
export const INTENSIDADE_DOR = 'intensidadeDor';
export const CARACTERIZACAO_DOR_TORACICA = 'caracterizacaoDorToracica';
export const CLASSIFICACAO_DOR = 'classificacaoDor';
export const TIPO_DOR = 'tipoDor';
export const OUTRO_TIPO_DOR = 'outroTipoDor';