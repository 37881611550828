import React, { useContext, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import Form from './form';
import {
    SttExpansionPanel,
    SttTextItem,
    SttHeading,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttGrid,
    SttTranslateHook,
    SttDivider
} from '@stt-componentes/core';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    titulo: {
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        marginBottom: theme.spacing(2)
    },
    subtitulo: {
        display: 'flex',
        marginBottom: "5px"
    },
    conteinerLesao: {
        marginBottom: theme.spacing(1)
    },
    textItemWrapper: {
        padding: 0
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    },

}));

const Laudo = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { dados, callbackProximoExame, DivBotoes } = props;
    const classes = useStyles();

    useEffect(() => {
        console.log(dados);
    }, [])

    return (
        <SttGrid container spacing={3} className={classes.dados}>
            <SttGrid item xs={12}>
                <SttGrid container>
                    <SttGrid item xs={12} md={6}>
                        <SttHeading variant="h3" color="primary" align="left" className={classes.titulo}>
                            {dados.exame.nome_paciente && <span>{`${dados.exame.nome_paciente}`}</span>}
                            {dados.exame.data_nascimento_paciente_formatada && <span>{`${dados.exame.data_nascimento_paciente_formatada}`}</span>}
                        </SttHeading>
                    </SttGrid>

                    <SttGrid item xs={12} md={6}>
                        <DivBotoes />
                    </SttGrid>
                </SttGrid>

                <SttExpansionPanel
                    title={strings.informacoesExame}
                    compact
                    opened={false}
                    children={
                        <>
                            <div>
                                {dados.exame.data_exame && <SttTextItem key={uuidv4()} title={strings.data} content={dados.exame.data_exame} wrapperClass={classes.textItemWrapper} />}
                                {
                                    dados.exame.nome_instituicao && dados.exame.nome_cidade && dados.exame.sigla_uf &&
                                    <SttTextItem key={uuidv4()} title={strings.instituicao} content={`${dados.exame.nome_instituicao} (${dados.exame.nome_cidade}/${dados.exame.sigla_uf})`} wrapperClass={classes.textItemWrapper} />
                                }
                                {dados.indicacaoEletro.sexo_paciente && <SttTextItem key={uuidv4()} title={strings.sexo} content={dados.indicacaoEletro.sexo_paciente} wrapperClass={classes.textItemWrapper} />}
                                {dados.indicacaoEletro.peso && <SttTextItem key={uuidv4()} title={strings.peso} content={`${dados.indicacaoEletro.peso} kg`} wrapperClass={classes.textItemWrapper} />}
                                {dados.indicacaoEletro.altura && <SttTextItem key={uuidv4()} title={strings.altura} content={`${dados.indicacaoEletro.altura} cm`} wrapperClass={classes.textItemWrapper} />}
                                {dados.exame.profissao_paciente && <SttTextItem key={uuidv4()} title={strings.profissao} content={dados.exame.profissao_paciente} wrapperClass={classes.textItemWrapper} />}
                            </div>

                            <SttDivider className={classes.divider} />

                            {
                                dados.exame &&
                                <div>
                                    <SttHeading variant="h3" color="primary" align="left" className={classes.subtitulo}>
                                        <span>{`${strings.indicacaoClinica}`}</span>
                                    </SttHeading>
                                    {dados.exame.observacao_tecnica && <SttTextItem key={uuidv4()} title={strings.observacao} content={dados.exame.observacao_tecnica} wrapperClass={classes.textItemWrapper} />}
                                    <SttTextItem key={uuidv4()} title={strings.preOperatorio} content={dados.indicacaoEletro.pre_operatorio ? strings.sim : strings.nao} wrapperClass={classes.textItemWrapper} />
                                    {dados.indicacaoEletro.motivosSolicitacaoFormatado && <SttTextItem key={uuidv4()} title={strings.motivoExame} content={dados.indicacaoEletro.motivosSolicitacaoFormatado} />}
                                    <SttTextItem key={uuidv4()} title={strings.dorMomentoExame} content={dados.indicacaoEletro.dor_momento_exame ? strings.sim : strings.nao} />
                                    {dados.indicacaoEletro.classificacaoDor && <SttTextItem key={uuidv4()} title={strings.classificacaoDor} content={dados.indicacaoEletro.classificacaoDor.descricao} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroDorIsquemica && <SttTextItem key={uuidv4()} title={strings.dorCardiacaIsquemica} content={dados.indicacaoEletro.descricaoIndicacaoEletroDorIsquemica} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoCardiaca && <SttTextItem key={uuidv4()} title={strings.dorNaoCardiaca} content={dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoCardiaca} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoIsquemica && <SttTextItem key={uuidv4()} title={strings.dorCardiacaNaoIsquemica} content={dados.indicacaoEletro.descricaoIndicacaoEletroDorNaoIsquemica} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroSintomaDor && <SttTextItem key={uuidv4()} title={strings.sintomasAssociados} content={dados.indicacaoEletro.descricaoIndicacaoEletroSintomaDor} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroLocalizacaoDor && <SttTextItem key={uuidv4()} title={strings.localizacaoIrradiacaoDor} content={dados.indicacaoEletro.descricaoIndicacaoEletroLocalizacaoDor} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroMedicamento && <SttTextItem key={uuidv4()} title={strings.medicamentosUso} content={dados.indicacaoEletro.descricaoIndicacaoEletroMedicamento} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroMotivoExame && <SttTextItem key={uuidv4()} title={strings.motivosExame} content={dados.indicacaoEletro.descricaoIndicacaoEletroMotivoExame} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroSintomaDor && <SttTextItem key={uuidv4()} title={strings.sintomasAssociados} content={dados.indicacaoEletro.descricaoIndicacaoEletroSintomaDor} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroValvulopatia && <SttTextItem key={uuidv4()} title={strings.valvulopatia} content={dados.indicacaoEletro.descricaoIndicacaoEletroValvulopatia} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroTipoDor && <SttTextItem key={uuidv4()} title={strings.tipoDor} content={dados.indicacaoEletro.descricaoIndicacaoEletroTipoDor} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroDorToracica && <SttTextItem key={uuidv4()} title={strings.caracterizacaoDorToracica} content={dados.indicacaoEletro.descricaoIndicacaoEletroDorToracica} />}
                                    {dados.indicacaoEletro.descricaoIndicacaoEletroFatorRisco && <SttTextItem key={uuidv4()} title={strings.comorbidadesFatoresRisco} content={dados.indicacaoEletro.descricaoIndicacaoEletroFatorRisco} />}
                                    {dados.indicacaoEletro.outras_hipoteses_diagnosticas && <SttTextItem key={uuidv4()} title={strings.outrasHipotesesDiagnosticas} content={dados.indicacaoEletro.outras_hipoteses_diagnosticas} />}
                                    {
                                        (dados.indicacaoEletro?.descricaoHistoricoDor?.data_historico_dor_formatada ||
                                            dados.indicacaoEletro?.descricaoHistoricoDor?.duracao_historico_dor ||
                                            dados.indicacaoEletro?.descricaoHistoricoDor?.tempo_historico_dor) &&
                                        <div>
                                            {dados.indicacaoEletro.descricaoHistoricoDor.data_historico_dor_formatada && <SttTextItem key={uuidv4()} title={strings.data} content={dados.indicacaoEletro.descricaoHistoricoDor.data_historico_dor_formatada} />}
                                            {dados.indicacaoEletro.descricaoHistoricoDor.duracao_historico_dor && <SttTextItem key={uuidv4()} title={strings.duracao} content={`${dados.indicacaoEletro.descricaoHistoricoDor.duracao_historico_dor.hours} horas e ${dados.indicacaoEletro.descricaoHistoricoDor.duracao_historico_dor.minutes} minutos`} />}
                                            {dados.indicacaoEletro.descricaoHistoricoDor.tempo_historico_dor && <SttTextItem key={uuidv4()} title={strings.tempoApresentaSintoma} content={dados.indicacaoEletro.descricaoHistoricoDor.tempo_historico_dor + ' ' + dados.indicacaoEletro.descricaoHistoricoDor.descricao_especificacao_tempo_historico_dor} />}
                                        </div>
                                    }
                                </div>
                            }

                            {
                                dados.indicacaoEletro?.cid10?.length > 0 &&
                                <>
                                    <SttDivider className={classes.divider} />
                                    <SttHeading variant="h3" color="primary" align="left" className={classes.subtitulo}>
                                        <span>{`${strings.cid10}`}</span>
                                    </SttHeading>
                                    <SttTable>
                                        <SttTableHead>
                                            <SttTableRow>
                                                <SttTableCell width="40%">
                                                    {strings.categoria}
                                                </SttTableCell>
                                                <SttTableCell width="60%">
                                                    {strings.subcategoria}
                                                </SttTableCell>
                                            </SttTableRow>
                                        </SttTableHead>
                                        <SttTableBody>
                                            {
                                                dados.indicacaoEletro.cid10.map((row, index) => (
                                                    <SttTableRow key={index}>
                                                        <SttTableCell>{row.descricao_categoria}</SttTableCell>
                                                        <SttTableCell>{row.descricao_subcategoria}</SttTableCell>
                                                    </SttTableRow>
                                                ))
                                            }
                                        </SttTableBody>
                                    </SttTable>

                                </>
                            }

                            <SttDivider className={classes.divider} />

                            <div>
                                <SttHeading variant="h3" color="primary" align="left" className={classes.subtitulo}>
                                    <span>{`${strings.realizacaoExame}`}</span>
                                </SttHeading>
                                {<SttTextItem key={uuidv4()} title={strings.dorMomentoExame} content={dados.exame.dor_momento_exame ? strings.sim : strings.nao} />}
                                {dados.exame.intensidade_dor && <SttTextItem key={uuidv4()} title={strings.intensidadeDor} content={dados.exame.intensidade_dor} />}
                                {dados.exame.descricaoLocalizacaoDorRealizacaoExame && <SttTextItem key={uuidv4()} title={strings.localizacaoIrradiacaoDor} content={dados.exame.descricaoLocalizacaoDorRealizacaoExame} />}
                                {dados.exame.descricaoTipoDorRealizacaoExame && <SttTextItem key={uuidv4()} title={strings.tipoDor} content={dados.exame.descricaoTipoDorRealizacaoExame} />}
                                {dados.exame.descricaoDorToracicaRealizacaoExame && <SttTextItem key={uuidv4()} title={strings.dorToracica} content={dados.exame.descricaoDorToracicaRealizacaoExame} />}
                            </div>
                        </>
                    }
                />


                <SttDivider />
                <Form
                    idExame={dados.exame.id_exame}
                    idLaudoEmissao={dados.idLaudoEmissao}
                    idPaciente={dados.exame.id_paciente}
                    idSolicitante={dados.exame.id_medico_solicitante}
                    nomeSolicitante={dados.exame.nome_medico_solicitante}
                    callbackProximoExame={callbackProximoExame}
                    descritoresLaudo={dados.descritoresLaudo}
                    laudoGrave={dados.exame.grave}
                />
            </SttGrid>
        </SttGrid>
    )
}

Laudo.propTypes = {
    strings: PropTypes.object.isRequired,
    dados: PropTypes.object.isRequired,
    callbackProximoExame: PropTypes.func
};

export default Laudo;