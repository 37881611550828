import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { SttTranslateHook } from '@stt-componentes/core';
import PesquisaSolicitacoes from '../../componentes/envio-imagens/grid-solicitacoes';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(3)
    }
}));

const EnvioImagens = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <PesquisaSolicitacoes strings={strings} />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(EnvioImagens);