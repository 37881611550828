import React, { Fragment } from 'react';
import {
    SttGrid,
    SttInput
} from '@stt-componentes/core';
import {
    EQUIPAMENTO,
    OBSERVACAO
} from './fieldNames';
import { Field, FastField } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Equipamento from '../informacoes-gerais/equipamento';
import { temPerfilRBAC } from '../../../security/acl';
import { PERFIL } from '../../../common/Constants';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 0
    }
}));

const DadosGerais = ({ strings, user, equipamentos }) => {
    const classes = useStyles();

    return (
        <Fragment>
            <SttGrid container spacing={3} className={classes.container}>
                <SttGrid item xs={12} md={6}>
                    <Field name={EQUIPAMENTO}>
                        {(props) => (
                            <Equipamento equipamentos={equipamentos} strings={strings} {...props} />
                        )}
                    </Field>
                </SttGrid>
                {
                    !temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE) &&
                    <SttGrid item xs={12} sm={12}>
                        <FastField name={OBSERVACAO}>
                            {({
                                field,
                                meta
                            }) => (
                                <SttInput
                                    multiline
                                    rows={4}
                                    {...field}
                                    label={strings.observacoes}
                                    error={meta.touched && meta.error ? true : false}
                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                />
                            )}
                        </FastField>
                    </SttGrid>
                }
            </SttGrid>
        </Fragment>
    );
}

export default DadosGerais;