import * as yup from 'yup'
import { MEDICAMENTOS_USO, COMORBIDADES_FATORES_RISCO, FATORES_RISCO, OUTROS_MEDICAMENTOS } from './fieldNames'

export default (strings) => {
    return yup.object().shape({
        [COMORBIDADES_FATORES_RISCO]: yup.object().shape({
            [MEDICAMENTOS_USO]: yup.array().when(OUTROS_MEDICAMENTOS, {
                is: (val) => {
                    return !val || val.trim() === '';
                },
                then: yup.array().of(
                    yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string(),
                        nenhumaInformacao: yup.boolean()
                    })
                ).min(1, strings.medicamentosUsoObrigatorio)
            }),
            [FATORES_RISCO]: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    descricao: yup.string(),
                    nenhumaInformacao: yup.boolean()
                }).required()
            ).min(1, strings.fatoresRiscoObrigatorio).required()
        }).required()
    })
};