import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { SttNotification, SttLoading, SttGrid, SttButton, SttTranslateHook } from '@stt-componentes/core';
import Laudo from '../index';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    botao: {
        width: '100%',
        padding: theme.spacing(2),
        textAlign: 'center'
    }
}));

const Visualizacao = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { id } = props;
    const classes = useStyles();

    const [idExame, setIdExame] = useState(null);
    const [carregandoExame, setCarregandoExame] = useState(true);
    const [msgCarregando, setMsgCarregando] = useState(strings.carregandoExame);
    const [nenhumExameLaudo, setNenhumExameLaudo] = useState(false);
    const [proximoExame, setProximoExame] = useState(true);
    const [erro, setErro] = useState(false);

    const notificar = (mensagem) => {
        setMsgCarregando(mensagem);
        if (!carregandoExame) {
            setCarregandoExame(true);
        }
    }

    const callbackProximoExame = () => {
        setMsgCarregando(strings.carregandoExame);
        setErro(false);
        setIdExame(null);
        if (!carregandoExame) {
            setCarregandoExame(true);
        }
        setProximoExame(true);
    }

    const callbackExameCarregado = () => {
        setCarregandoExame(false);
    }

    useEffect(() => {
        const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;
        if (proximoExame) {
            axios
                .get(`${ELETRO_API_BASE_URL}/fabrica-laudo?rede=${id}`, { headers: getHeaders(), timeout: 15000 })
                .then((response) => {
                    const { data } = response;
                    if (!data.data.idExame) {
                        setNenhumExameLaudo(true);
                        callbackExameCarregado();
                        return;
                    }
                    setIdExame(data.data.idExame);
                })
                .catch(err => {
                    callbackExameCarregado();
                    setErro(true);
                    console.error(err);
                })
                .finally (() => {
                    // Redefine variável de controle
                    setProximoExame(false);
                });
        }
    }, [id, proximoExame]);

    return (
        <>
            <div className={classes.carregando}>
                <SttLoading
                    open={carregandoExame}
                    text={msgCarregando}
                />
            </div>

            {
                (erro && 
                <SttGrid container spacing={3}>
                    <SttGrid item xs={12}>
                    <SttNotification severity="error">
                        {strings.erroSortearExame}
                    </SttNotification>
                        <div className={classes.botao}>
                            <SttButton 
                                nomarginleft="true"
                                variant="contained" 
                                color="primary"
                                onClick={() => callbackProximoExame()}>
                                {strings.tentarNovamente}
                            </SttButton>
                        </div>
                    </SttGrid>
                </SttGrid>) ||
                (idExame ? 
                    <Laudo 
                        idExame={parseInt(idExame)} 
                        idRede={id}
                        callbackProximoExame={callbackProximoExame}
                        callbackExameCarregado={callbackExameCarregado}
                        notificar={notificar}
                    /> 
                    : 
                    nenhumExameLaudo && 
                    <SttNotification severity="info">
                        {strings.nenhummExameParaLaudo}
                    </SttNotification>)
            }
        </>
    )

}

Visualizacao.propTypes = {
    strings: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    tipo: PropTypes.string
};

export default Visualizacao;