import * as yup from 'yup'
import {
    INVESTIGACAO_DOR,
    DOR_MOMENTO_EXAME,
    DATA_ULTIMO_EPISODIO,
    DURACAO_ULTIMO_EPISODIO,
    DURACAO_EPISODIO,
    TEMPO_APRESENTA_SINTOMA,
    ESPECIFICACAO_TEMPO_APRESENTA_SINTOMA,
    LOCALIZACAO_IRRADIACAO_DOR,
    SINTOMAS_ASSOCIADOS,
    INTENSIDADE_DOR,
    CARACTERIZACAO_DOR_TORACICA,
    TIPO_DOR,
    OUTRO_TIPO_DOR
} from './fieldNames';

import { IDENTIFICACAO_EXAME, MOTIVOS_SOLICITACAO } from '../../../componentes/solicitacao/identificacaoExame/form/fieldNames';
import { CONSTANTES_MOTIVOS_SOLICITACAO } from '../../../common/Constants';

export default (strings) => {
    return yup.object().shape({
        [INVESTIGACAO_DOR]: yup.object().when(`${IDENTIFICACAO_EXAME}.${MOTIVOS_SOLICITACAO}`, {
            is: (val) => {
                return val.some((motivo) => motivo.identificador == CONSTANTES_MOTIVOS_SOLICITACAO.DOR_TORACICA);
            },
            then: yup.object()
                .shape({
                    [DOR_MOMENTO_EXAME]: yup.string().matches(/(S|N)/).required(strings.campoObrigatorio),
                    [DATA_ULTIMO_EPISODIO]: yup.date()
                        .nullable()
                        .typeError(strings.dataInvalida)
                        .max(new Date(), strings.erroDataFutura)
                        .when(`${DOR_MOMENTO_EXAME}`, {
                            is: (val) => val === 'N',
                            then: yup.date().nullable().required(strings.campoObrigatorio)
                        }),
                    [DURACAO_ULTIMO_EPISODIO]: yup.string()
                        .nullable()
                        .when(`${DOR_MOMENTO_EXAME}`, {
                            is: (val) => val === 'N',
                            then: yup.string().nullable().test('horaMinutoInvalida', strings.horaInvalida, (val) => {
                                val = val || '';
                                return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(val);
                            }).test('tamanho', strings.validacaoDuracaoUltimoEpisodioTamanho, (val) => {
                                val = val || '';
                                return val.length === 5;
                            }).required(strings.campoObrigatorio)
                        }),
                    [TEMPO_APRESENTA_SINTOMA]: yup.string()
                        .nullable()
                        .when(`${DOR_MOMENTO_EXAME}`, {
                            is: (val) => val === 'N',
                            then: yup.string().required(strings.campoObrigatorio)
                        }),
                    [ESPECIFICACAO_TEMPO_APRESENTA_SINTOMA]: yup.object()
                        .nullable()
                        .when(`${DOR_MOMENTO_EXAME}`, {
                            is: (val) => val === 'N',
                            then: yup.object().shape({
                                id: yup.number(),
                                descricao: yup.string()
                            }).nullable().required(strings.campoObrigatorio)
                        }),
                    [DURACAO_EPISODIO]: yup.string()
                        .nullable()
                        .when(`${DOR_MOMENTO_EXAME}`, {
                            is: (val) => val === 'S',
                            then: yup.string().nullable().test('horaMinutoInvalida', strings.horaInvalida, (val) => {
                                val = val || '';
                                return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(val);
                            }).test('tamanho', strings.validacaoDuracaoUltimoEpisodioTamanho, (val) => {
                                val = val || '';
                                return val.length === 5;
                            }).required(strings.campoObrigatorio)
                        }),
                    [LOCALIZACAO_IRRADIACAO_DOR]: yup.array().of(
                        yup.object().shape({
                            id: yup.number(),
                            descricao: yup.string()
                        }).required()
                    ).min(1, strings.localizacaoIrradiacaoDorObrigatoria),
                    [SINTOMAS_ASSOCIADOS]: yup.array().of(
                        yup.object().shape({
                            id: yup.number(),
                            descricao: yup.string(),
                            nenhuma_informacao: yup.boolean()
                        }).required()
                    ).min(1, strings.sintomasAssociadosObrigatorio),
                    [TIPO_DOR]: yup.array().of(
                        yup.object().shape({
                            id: yup.number(),
                            descricao: yup.string(),
                            necessita_complemento: yup.boolean()
                        }).required()
                    ).min(1, strings.tipoDorObrigatorio),
                    [OUTRO_TIPO_DOR]: yup.string()
                        .nullable()
                        .when(`${TIPO_DOR}`, {
                            is: (val) => val.find(v => v.necessita_complemento),
                            then: yup.string().nullable().required(strings.campoObrigatorio)
                        }),
                    [INTENSIDADE_DOR]: yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string()
                    }).nullable().required(strings.campoObrigatorio),
                    [CARACTERIZACAO_DOR_TORACICA]: yup.array().of(
                        yup.object().shape({
                            id: yup.number(),
                            descricao: yup.string(),
                            nenhuma_informacao: yup.boolean()
                        }).required()
                    ).min(1, strings.caracterizacaoDorToracicaObrigatoria),
                }).required()
        })
    })
}