import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { getHeaders } from '../../request';
import { SttTranslateHook } from '@stt-componentes/core';
import SttGaleriaImagens from '@stt-componentes/galeria-telediagnostico';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import ListaAnexos from '../exame/listaAnexos';

const useStyles = makeStyles((theme) => ({
    divListaImagens: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
}));

const Imagens = (props) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { idExame } = props;
    const EXAMES_API_BASE_URL = global.gConfig.url_base_exames;
    const anexosController  = useRef();
    const [arquivosExternos, setArquivosExternos] = useState(null);

    useEffect(() => {
        if (anexosController.current) {
            anexosController.current.abort();
        }
        anexosController.current = new AbortController();
        axios.get(`${EXAMES_API_BASE_URL}/exame/${idExame}/anexo`, { headers: getHeaders(), signal: anexosController.current.signal })
            .then((response) => {
                setArquivosExternos(response.data);
            })
            .catch(err => {
                console.log('err', err);
                // TODO: inserir notification de error
            });

            return () => {
                if(anexosController.current) {
                    anexosController.current.abort();
                }
            }
    }, []);

    return (
        <>
            <SttGaleriaImagens
                idExame={idExame}
                strings={strings}
                config={global.gConfig}
                tokenTelessaude={getHeaders()}
            />

            {
                arquivosExternos?.length > 0 &&
                <div className={classes.divListaImagens}>
                    <ListaAnexos anexos={arquivosExternos} />
                </div>
            }
        </>
    )

}

Imagens.propTypes = {
    strings: PropTypes.object.isRequired,
    idExame: PropTypes.number.isRequired,
    imagens: PropTypes.array.isRequired
};

export default Imagens;