import { 
    DESCRICAO_ESTUDO,
    DECS,
    ID_EXAME,
    ID_LAUDO_EMISSAO,
    TIMESTAMP_INICIO,
    ID_PACIENTE,
    ID_MEDICO_SOLICITANTE,
    NOME_MEDICO_SOLICITANTE,
    DESCRITORES_SBC,
    LAUDO_GRAVE
} from './fieldNames';
import { CID_10 } from '@stt-componentes/cid10/dist/lib/fieldNames';

export const values = (initialValues, idExame, idLaudoEmissao, idPaciente, idSolicitante, nomeSolicitante, descritoresLaudo, laudoGrave) => {
    let val = {
        [ID_EXAME]: idExame,
        [ID_LAUDO_EMISSAO]: idLaudoEmissao,
        [ID_PACIENTE]: idPaciente,
        [ID_MEDICO_SOLICITANTE]: idSolicitante,
        [NOME_MEDICO_SOLICITANTE]: nomeSolicitante,
        [DESCRICAO_ESTUDO]: 'Exame de Eletrocardiografia',
        [TIMESTAMP_INICIO]: new Date().getTime(),
        [DESCRITORES_SBC]: descritoresLaudo?.descritoresSbc || [],
        [CID_10]: descritoresLaudo?.cid10 || [],
        [DECS]: descritoresLaudo?.decs || [],
        [LAUDO_GRAVE]: laudoGrave
    };

    if (initialValues) {
        return {
            ...val,
            [TIMESTAMP_INICIO]: initialValues[TIMESTAMP_INICIO],
            [CID_10]: initialValues[CID_10],
            [DECS]: initialValues[DECS],
        }
    }

    return val;
}