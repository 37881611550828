export const DESCRICAO_ESTUDO = 'descricaoEstudo';
export const CID10 = 'cid10';
export const DESCRITORES_SBC = 'descritoresSBC';
export const DECS = 'decs';
export const ID_EXAME = 'idExame';
export const ID_LAUDO_EMISSAO = 'idLaudoEmissao';
export const TIMESTAMP_INICIO = 'timestampInicio';
export const ID_PACIENTE = 'idPaciente';
export const ID_MEDICO_SOLICITANTE = 'idMedicoSolicitante';
export const NOME_MEDICO_SOLICITANTE = 'nomeMedicoSolicitante';
// Constantes dos modificadores do descritor do laudo
export const MODIFICADOR = 'modificador';
export const ATRIBUTO = 'atributo';
export const OBSERVACAO = 'observacao';
export const LAUDO_GRAVE = 'laudoGrave';

