import React, { useState, memo, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setParametrosPesquisa as setParametrosPesquisaAction, reset as resetAction } from '../../redux/actions/exame';
import { Formik, Field, FastField } from 'formik';
import axios from 'axios';
import { useMoment } from '../../hooks';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import { estadoInicialForm } from './estadoInicialForm';
import { temPerfilRBAC } from '../../security/acl';
import { TIPO_ABA_EXAME, SITUACAO_LAUDO, SITUACAO, MODALIDADE, PERFIL } from '../../common/Constants';
import {
    SttGrid,
    SttButton,
    SttInput,
    SttDatePicker,
    SttFormControl,
    SttFormGroup,
    SttFormControlLabel,
    SttCheckbox,
    SttAutocomplete,
    SttAlerta,
    SttTranslateHook,
    SttInputProtocol,
    SttNumberInput,
    SttCard,
    SttCardHeader,
    SttCardContent,
    SttMaskedInput
} from '@stt-componentes/core';
import {
    UF,
    MUNICIPIO,
    INSTITUICAO,
    REDE,
    FUNCIONARIO_ENVIO,
    REQUISICAO,
    NOME_PACIENTE,
    DATA_EXAME_DE,
    DATA_EXAME_ATE,
    DATA_LAUDO_DE,
    DATA_LAUDO_ATE,
    SITUACAO_EXAME_LAUDO,
    SEM_LAUDO_72H,
    SITUACAO_EXAME,
    PROTOCOLO,
    NUMERO_EXAME,
    CPF_PACIENTE,
    CNS_PACIENTE
} from './fieldNames';

const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const FormPesquisa = (props) => {
    const {
        user,
        tipoAba,
        idAba,
        callbackBusca,
        callbackAndamento,
        buscar,
        filtros,
        laudador,
        setParametrosPesquisa,
        reset,
        resetPageCount,
        redes
    } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const schema = validationSchema(strings);
    const filtrosIniciais = estadoInicialForm(global.gConfig);
    const formRef = useRef();
    const moment = useMoment();

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    };

    // Alerta
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);

    // Filtros
    const [ufs, setUfs] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [instituicoes, setInstituicoes] = useState([]);
    const [filtroPorLocal, setFiltroPorLocal] = useState(false);
    const [filtroPorRede, setFiltroPorRede] = useState(false);
    const [filtroPorFuncionario, setFiltroPorFuncionario] = useState(false);
    const [carregarUfs, setCarregarUfs] = useState(false);

    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;


    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    useEffect(() => {
        if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) {
            setCarregarUfs(true);
            setFiltroPorLocal(true);
            setFiltroPorRede(true);
            setFiltroPorFuncionario(true);
        }
        if (temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE)) {
            setCarregarUfs(true);
            setFiltroPorLocal(true);
        }
        if (temPerfilRBAC(user, PERFIL.VISUALIZADOR_REDE)) {
            setCarregarUfs(true);
            setFiltroPorLocal(true);
        }
    }, []);

    useEffect(() => {
        if (carregarUfs) {
            let url = `${UTILITARIOS_API_BASE_URL}/localizacao/estado`;
            if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) {
                const perfil = user.perfisRBAC.filter(p => p.identificador === PERFIL.ADMINISTRADOR_ESTADUAL);
                const estados = perfil[0].estados;
                const idsUf = estados.map(e => e.id);
                url = `${url}?ids=${idsUf.join(',')}`;
            }

            axios
                .get(url, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        setUfs(itens);
                    }
                })
                .catch(err => console.log(err));
        }
    }, [carregarUfs])

    const handleChangeUf = (uf) => {
        if (filtroPorLocal && uf) {
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${uf.id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        setMunicipios(itens);
                    } else {
                        setMunicipios([]);
                    }
                })
                .catch(err => console.log(err));
        }
    };

    const handleChangeMunicipio = (municipio) => {
        if (filtroPorLocal && municipio) {
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/instituicao-equipamento?municipio=${municipio.id}&modalidade=${MODALIDADE.SIGLA}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        setInstituicoes(itens);
                    } else {
                        setInstituicoes([]);
                    }
                })
                .catch(err => console.log(err));
        }
    };

    const gerarFiltroImplicito = (filtros) => {
        switch (tipoAba) {
            case TIPO_ABA_EXAME.INSTITUICAO:
                filtros.instituicao = idAba;
                break;

            case TIPO_ABA_EXAME.PERFIL:
                if (temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE)) {
                    filtros.solicitante = user.idFuncionario;
                }
                break;
            case TIPO_ABA_EXAME.REDE:
                filtros.rede = idAba;
                if (temPerfilRBAC(user, PERFIL.MEDICO_LAUDADOR)) {
                    filtros.laudador = user.idFuncionario;
                }
                break;
        }
    }

    const estruturarFiltros = (dados) => {
        let filtrosPesquisa = {
            'sigla-modalidade': MODALIDADE.SIGLA,
            start: dados.start,
            count: dados.count
        };
        gerarFiltroImplicito(filtrosPesquisa);
        if (dados.uf) {
            filtrosPesquisa.uf = dados.uf.id;
        }
        if (dados.municipio) {
            filtrosPesquisa.municipio = dados.municipio.id;
        }
        if (dados.instituicao) {
            filtrosPesquisa.instituicao = dados.instituicao.id;
        }
        if (dados['funcionario-envio']) {
            filtrosPesquisa['funcionario-envio'] = dados['funcionario-envio'];
        }
        if (dados.requisicao) {
            filtrosPesquisa.requisicao = dados.requisicao;
        }
        if (dados.paciente.trim()) {
            filtrosPesquisa.paciente = dados.paciente.trim();
        }
        if (dados['cpf-paciente']) {
            filtrosPesquisa['cpf-paciente'] = dados['cpf-paciente'];
        }
        if (dados['cns-paciente']) {
            filtrosPesquisa['cns-paciente'] = dados['cns-paciente'];
        }
        if (dados.rede) {
            filtrosPesquisa.rede = dados.rede.id;
        }
        if (dados['data-exame-de']) {
            filtrosPesquisa['data-exame-de'] = moment(dados['data-exame-de']).format('YYYY-MM-DD');
        }
        if (dados['data-exame-ate']) {
            filtrosPesquisa['data-exame-ate'] = moment(dados['data-exame-ate']).format('YYYY-MM-DD');
        }
        if (dados['data-laudo-de']) {
            filtrosPesquisa['data-laudo-de'] = moment(dados['data-laudo-de']).format('YYYY-MM-DD');
        }
        if (dados['data-laudo-ate']) {
            filtrosPesquisa['data-laudo-ate'] = moment(dados['data-laudo-ate']).format('YYYY-MM-DD');
        }
        if (dados['situacao-laudo'].length > 0) {
            filtrosPesquisa['situacao-laudo'] = dados['situacao-laudo'].join('|');
        }
        if (dados.atrasado) {
            filtrosPesquisa.atrasado = dados.atrasado;
        }
        if (dados.protocolo) {
            filtrosPesquisa.protocolo = dados.protocolo;
        }
        if (dados['numero-exame']) {
            filtrosPesquisa['numero-exame'] = dados['numero-exame'];
        }
        if (dados.situacao.length > 0) {
            filtrosPesquisa.situacao = dados.situacao.join('|');
        }

        return filtrosPesquisa;
    };

    return (
        <>
            <Formik
                innerRef={formRef}
                initialValues={filtros}
                validationSchema={schema}
                onSubmit={(data, { setSubmitting }) => {

                    data.start = filtros.page * filtros.count;
                    data.count = filtros.count;
                    data.page = filtros.page;
                    data.sort = filtros.sort;
                    data.direction = filtros.direction;
                    setSubmitting(true);
                    callbackAndamento(true);
                    // Salva os parâmetros da pesquisa para manter estado do 
                    // formulário durante a navegação entre páginas
                    setParametrosPesquisa(data);

                    let params = estruturarFiltros(data);
                    let tipoAlertaPesquisa = '';
                    let tituloAlertaPesquisa = '';
                    let mensagemAlertaPesquisa = '';
                    axios.get(`${global.gConfig.url_base_exames}/exames`, { params: params, headers: getHeaders() })
                        .then((response) => {
                            const dados = response.data.data;
                            callbackBusca(dados);
                        })
                        .catch(err => {
                            const { response } = err;
                            let msg = strings.mensagemErroGeral;
                            if (response) {
                                if (response.status === HttpStatus.BAD_REQUEST) {
                                    const erro = response.data;
                                    let arrMensagem = [];
                                    erro.errors.forEach(error => {
                                        arrMensagem.push(`- ${error.message}`);
                                    });
                                    msg = arrMensagem.join('\n');
                                    tipoAlertaPesquisa = 'error';
                                    tituloAlertaPesquisa = erro.message;
                                    mensagemAlertaPesquisa = msg;
                                } else {
                                    tipoAlertaPesquisa = 'error';
                                    tituloAlertaPesquisa = strings.erro;
                                    mensagemAlertaPesquisa = msg;
                                }
                            } else {
                                tipoAlertaPesquisa = 'error';
                                tituloAlertaPesquisa = strings.erro;
                                mensagemAlertaPesquisa = msg;
                            }
                            setOpcoesAlerta([{
                                title: strings.ok,
                                onClick: handleCloseAlerta
                            }]);
                            setTipoAlerta(tipoAlertaPesquisa);
                            setTituloAlerta(tituloAlertaPesquisa);
                            setMensagemAlerta(mensagemAlertaPesquisa);
                            setMostrarAlerta(true);
                        })
                        .finally(() => {
                            setSubmitting(false);
                            callbackAndamento(false);
                        });
                }}
            >
                {
                    ({
                        values,
                        isSubmitting,
                        handleSubmit,
                        resetForm
                    }) => {
                        return (
                            <>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttCard variant="outlined">
                                                <SttCardHeader subheader={strings.data} />
                                                <SttCardContent>
                                                    <SttGrid container spacing={1}>
                                                        <SttGrid item xs={12} sm={4} md={2}>
                                                            <Field name={DATA_EXAME_DE}>
                                                                {({
                                                                    field: { name, value, },
                                                                    form: { setFieldValue, setFieldError, setFieldTouched },
                                                                    meta
                                                                }) => {
                                                                    let erroAtual = meta.error;
                                                                    return (
                                                                        <SttDatePicker
                                                                            label={strings.dataInicialExame}
                                                                            inputprops={{
                                                                                name: name
                                                                            }}
                                                                            disableFuture
                                                                            maxDate={new Date()}
                                                                            error={meta.touched && meta.error ? true : false}
                                                                            onError={error => {
                                                                                if (error && error !== erroAtual) {
                                                                                    setFieldError(DATA_EXAME_DE, error);
                                                                                }
                                                                            }}
                                                                            value={value}
                                                                            onBlur={() => {
                                                                                setFieldTouched(DATA_EXAME_DE, true);
                                                                            }}
                                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                            onChange={date => setFieldValue(DATA_EXAME_DE, date, true)}
                                                                            onClose={() => setFieldTouched(DATA_EXAME_DE, true)}
                                                                        />
                                                                    );
                                                                }}
                                                            </Field>
                                                        </SttGrid>
                                                        <SttGrid item xs={12} sm={4} md={2}>
                                                            <Field name={DATA_EXAME_ATE}>
                                                                {({
                                                                    field: { name, value, },
                                                                    form: { setFieldValue, setFieldError, setFieldTouched },
                                                                    meta
                                                                }) => {
                                                                    let erroAtual = meta.error;
                                                                    return (
                                                                        <SttDatePicker
                                                                            label={strings.dataFinalExame}
                                                                            inputprops={{
                                                                                name: name
                                                                            }}
                                                                            disableFuture
                                                                            maxDate={new Date()}
                                                                            error={meta.touched && meta.error ? true : false}
                                                                            onError={error => {
                                                                                if (error && error !== erroAtual) {
                                                                                    setFieldError(DATA_EXAME_ATE, error);
                                                                                }

                                                                            }}
                                                                            value={value}
                                                                            onBlur={() => {
                                                                                setFieldTouched(DATA_EXAME_ATE, true);
                                                                            }}
                                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                            onChange={date => setFieldValue(DATA_EXAME_ATE, date, true)}
                                                                            onClose={() => setFieldTouched(DATA_EXAME_ATE, true)}
                                                                        />
                                                                    );
                                                                }}
                                                            </Field>
                                                        </SttGrid>
                                                        <SttGrid item xs={12} sm={4} md={2}>
                                                            <Field name={DATA_LAUDO_DE}>
                                                                {({
                                                                    field: { name, value, },
                                                                    form: { setFieldValue, setFieldError, setFieldTouched },
                                                                    meta
                                                                }) => {
                                                                    let erroAtual = meta.error;
                                                                    return (
                                                                        <SttDatePicker
                                                                            label={strings.dataInicialLaudo}
                                                                            inputprops={{
                                                                                name: name
                                                                            }}
                                                                            disableFuture
                                                                            maxDate={new Date()}
                                                                            error={meta.touched && meta.error ? true : false}
                                                                            onError={error => {
                                                                                if (error && error !== erroAtual) {
                                                                                    setFieldError(DATA_LAUDO_DE, error);
                                                                                }
                                                                            }}
                                                                            value={value}
                                                                            onBlur={() => {
                                                                                setFieldTouched(DATA_LAUDO_DE, true);
                                                                            }}
                                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                            onChange={date => setFieldValue(DATA_LAUDO_DE, date, true)}
                                                                            onClose={() => setFieldTouched(DATA_LAUDO_DE, true)}
                                                                        />
                                                                    );
                                                                }}
                                                            </Field>
                                                        </SttGrid>
                                                        <SttGrid item xs={12} sm={4} md={2}>
                                                            <Field name={DATA_LAUDO_ATE}>
                                                                {({
                                                                    field: { name, value, },
                                                                    form: { setFieldValue, setFieldError, setFieldTouched },
                                                                    meta
                                                                }) => {
                                                                    let erroAtual = meta.error;
                                                                    return (
                                                                        <SttDatePicker
                                                                            label={strings.dataFinalLaudo}
                                                                            inputprops={{
                                                                                name: name
                                                                            }}
                                                                            disableFuture
                                                                            maxDate={new Date()}
                                                                            error={meta.touched && meta.error ? true : false}
                                                                            onError={error => {
                                                                                if (error && error !== erroAtual) {
                                                                                    setFieldError(DATA_LAUDO_ATE, error);
                                                                                }

                                                                            }}
                                                                            value={value}
                                                                            onBlur={() => {
                                                                                setFieldTouched(DATA_LAUDO_ATE, true);
                                                                            }}
                                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                            onChange={date => setFieldValue(DATA_LAUDO_ATE, date, true)}
                                                                            onClose={() => setFieldTouched(DATA_LAUDO_ATE, true)}
                                                                        />
                                                                    );
                                                                }}
                                                            </Field>
                                                        </SttGrid>
                                                    </SttGrid>
                                                </SttCardContent>
                                            </SttCard>
                                        </SttGrid>

                                        <SttGrid item xs={12}>
                                            <SttCard variant="outlined">
                                                <SttCardHeader subheader={strings.situacao} />
                                                <SttCardContent>
                                                    <SttGrid container spacing={1}>
                                                        <SttGrid item xs={12} sm={12} md={9} lg={9}>
                                                            <Field name={SITUACAO_EXAME_LAUDO}>
                                                                {({
                                                                    field,
                                                                    form
                                                                }) => (
                                                                    <SttFormControl variant="outlined">
                                                                        <SttFormGroup row>
                                                                            <SttFormControlLabel
                                                                                control={
                                                                                    <SttCheckbox
                                                                                        {...field}
                                                                                        value={SITUACAO_LAUDO.COM_LAUDO}
                                                                                        color="primary"
                                                                                        checked={!form.values[SEM_LAUDO_72H] && form.values[SITUACAO_EXAME_LAUDO].includes(SITUACAO_LAUDO.COM_LAUDO)}
                                                                                        disabled={form.values[SEM_LAUDO_72H]}
                                                                                    />
                                                                                }
                                                                                label={strings.comLaudo}
                                                                            />
                                                                            <SttFormControlLabel
                                                                                control={
                                                                                    <SttCheckbox
                                                                                        {...field}
                                                                                        value={SITUACAO_LAUDO.SEM_LAUDO}
                                                                                        color="primary"
                                                                                        checked={form.values[SITUACAO_EXAME_LAUDO].includes(SITUACAO_LAUDO.SEM_LAUDO)}
                                                                                    />
                                                                                }
                                                                                label={strings.semLaudo}
                                                                            />
                                                                            <SttFormControlLabel
                                                                                control={
                                                                                    <SttCheckbox
                                                                                        {...field}
                                                                                        value={SITUACAO_LAUDO.LAUDO_EMISSAO}
                                                                                        color="primary"
                                                                                        checked={!form.values[SEM_LAUDO_72H] && form.values[SITUACAO_EXAME_LAUDO].includes(SITUACAO_LAUDO.LAUDO_EMISSAO)}
                                                                                        disabled={form.values[SEM_LAUDO_72H]}
                                                                                    />
                                                                                }
                                                                                label={strings.laudoEmissao}
                                                                            />
                                                                        </SttFormGroup>
                                                                    </SttFormControl>
                                                                )}
                                                            </Field>
                                                            <Field name={SEM_LAUDO_72H}>
                                                                {({
                                                                    field,
                                                                    form
                                                                }) => (
                                                                    <SttFormControl variant="outlined">
                                                                        <SttFormGroup row>
                                                                            <SttFormControlLabel
                                                                                control={
                                                                                    <SttCheckbox
                                                                                        {...field}
                                                                                        value={true}
                                                                                        color="primary"
                                                                                        checked={form.values[SEM_LAUDO_72H]}
                                                                                        onChange={event => {
                                                                                            if (event.target.checked) {
                                                                                                form.setFieldValue(SEM_LAUDO_72H, true);
                                                                                                form.setFieldValue(SITUACAO_EXAME_LAUDO, ['N']);
                                                                                            } else {
                                                                                                form.setFieldValue(SEM_LAUDO_72H, false);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                }
                                                                                label={strings.semLaudo72h}
                                                                            />
                                                                        </SttFormGroup>
                                                                    </SttFormControl>
                                                                )}
                                                            </Field>
                                                        </SttGrid>
                                                        <SttGrid item xs={12} sm={12} md={3} lg={3}>
                                                            <Field name={SITUACAO_EXAME}>
                                                                {({
                                                                    field,
                                                                    form
                                                                }) => (
                                                                    <SttFormControl variant="outlined">
                                                                        <SttFormGroup row>
                                                                            <SttFormControlLabel
                                                                                control={
                                                                                    <SttCheckbox
                                                                                        {...field}
                                                                                        value={SITUACAO.VALIDO}
                                                                                        color="primary"
                                                                                        checked={form.values[SITUACAO_EXAME].includes(SITUACAO.VALIDO)}
                                                                                    />
                                                                                }
                                                                                label={strings.valido}
                                                                            />
                                                                            <SttFormControlLabel
                                                                                control={
                                                                                    <SttCheckbox
                                                                                        {...field}
                                                                                        value={SITUACAO.INVALIDO}
                                                                                        color="primary"
                                                                                        checked={form.values[SITUACAO_EXAME].includes(SITUACAO.INVALIDO)}
                                                                                    />
                                                                                }
                                                                                label={strings.invalido}
                                                                            />
                                                                        </SttFormGroup>
                                                                    </SttFormControl>
                                                                )}
                                                            </Field>
                                                        </SttGrid>
                                                    </SttGrid>
                                                </SttCardContent>
                                            </SttCard>
                                        </SttGrid>

                                        {
                                            (filtroPorLocal || filtroPorRede) &&
                                            <SttGrid item xs={12}>
                                                <SttCard variant="outlined">
                                                    <SttCardHeader subheader={strings.localizacao} />
                                                    <SttCardContent>
                                                        <SttGrid container spacing={3}>
                                                            {
                                                                filtroPorLocal &&
                                                                <>
                                                                    <SttGrid item xs={12} sm={3} md={2} lg={1} xl={1}>
                                                                        <Field name={UF}>
                                                                            {({
                                                                                field: { name, value, onBlur },
                                                                                form: { setFieldValue }
                                                                            }) => (
                                                                                <SttAutocomplete
                                                                                    inputprops={{
                                                                                        name: name,
                                                                                        label: strings.uf
                                                                                    }}
                                                                                    getOptionLabel={option => option && option.sigla || ''}
                                                                                    getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                                                    options={ufs}
                                                                                    value={value}
                                                                                    onBlur={onBlur}
                                                                                    onChange={(e, item) => {
                                                                                        setFieldValue(UF, item || null);
                                                                                        setFieldValue(MUNICIPIO, null);
                                                                                        handleChangeUf(item);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </SttGrid>
                                                                    <SttGrid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                                                        <Field name={MUNICIPIO}>
                                                                            {({
                                                                                field: { name, value, onBlur },
                                                                                form: { setFieldValue }
                                                                            }) => (
                                                                                <SttAutocomplete
                                                                                    inputprops={{
                                                                                        name: name,
                                                                                        label: strings.municipio
                                                                                    }}
                                                                                    getOptionLabel={option => option && option.nome || ''}
                                                                                    getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                                                    options={municipios}
                                                                                    value={value}
                                                                                    onBlur={onBlur}
                                                                                    onChange={(e, item) => {
                                                                                        setFieldValue(MUNICIPIO, item || null);
                                                                                        setFieldValue(INSTITUICAO, null);
                                                                                        handleChangeMunicipio(item);
                                                                                    }}
                                                                                    disabled={!values[UF]}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </SttGrid>
                                                                    <SttGrid item xs={12} sm={5} md={6} lg={5} xl={2}>
                                                                        <Field name={INSTITUICAO}>
                                                                            {({
                                                                                field: { name, value, onBlur },
                                                                                form: { setFieldValue }
                                                                            }) => (
                                                                                <SttAutocomplete
                                                                                    inputprops={{
                                                                                        name: name,
                                                                                        label: strings.instituicao
                                                                                    }}
                                                                                    getOptionLabel={option => option && option.nome || ''}
                                                                                    getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                                                    options={instituicoes}
                                                                                    value={value}
                                                                                    onBlur={onBlur}
                                                                                    onChange={(e, item) => {
                                                                                        setFieldValue(INSTITUICAO, item || null);
                                                                                    }}
                                                                                    disabled={!values[MUNICIPIO]}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                    </SttGrid>
                                                                </>
                                                            }
                                                            {
                                                                filtroPorRede &&
                                                                <SttGrid item xs={12} sm={4} md={3} lg={3} xl={2}>
                                                                    <Field name={REDE}>
                                                                        {({
                                                                            field: { name, value, onBlur },
                                                                            form: { setFieldValue }
                                                                        }) => (
                                                                            <SttAutocomplete
                                                                                inputprops={{
                                                                                    name: name,
                                                                                    label: strings.rede
                                                                                }}
                                                                                getOptionLabel={option => option && option.descricao || ''}
                                                                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                                                options={redes}
                                                                                value={value}
                                                                                onBlur={onBlur}
                                                                                onChange={(e, item) => {
                                                                                    setFieldValue(REDE, item || null);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </SttGrid>
                                                            }
                                                        </SttGrid>
                                                    </SttCardContent>
                                                </SttCard>
                                            </SttGrid>
                                        }

                                        <SttGrid item xs={12}>
                                            <SttCard variant="outlined">
                                                <SttCardHeader subheader={strings.exame} />
                                                <SttCardContent>
                                                    <SttGrid container spacing={1}>
                                                        <SttGrid item xs={12} sm={4} md={2}>
                                                            <Field name={REQUISICAO}>
                                                                {({
                                                                    field
                                                                }) => (
                                                                    <SttInput
                                                                        {...field}
                                                                        label={strings.requisicao}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </SttGrid>
                                                        <SttGrid item xs={12} sm={4} md={2}>
                                                            <Field name={PROTOCOLO}>
                                                                {({
                                                                    field
                                                                }) => (
                                                                    <SttInputProtocol
                                                                        label={strings.protocolo}
                                                                        {...field}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </SttGrid>
                                                        <SttGrid item xs={12} sm={4} md={2}>
                                                            <Field name={NUMERO_EXAME}>
                                                                {({
                                                                    field
                                                                }) => (
                                                                    <SttNumberInput
                                                                        label={strings.numeroLaudo}
                                                                        {...field}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </SttGrid>
                                                        {
                                                            filtroPorFuncionario &&
                                                            <SttGrid item xs={12} sm={4} md={2}>
                                                                <Field name={FUNCIONARIO_ENVIO}>
                                                                    {({
                                                                        field
                                                                    }) => (
                                                                        <SttInput
                                                                            {...field}
                                                                            label={strings.responsavelEnvio}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </SttGrid>
                                                        }
                                                    </SttGrid>
                                                </SttCardContent>
                                            </SttCard>
                                        </SttGrid>

                                        <SttGrid item xs={12}>
                                            <SttCard variant="outlined">
                                                <SttCardHeader subheader={strings.paciente} />
                                                <SttCardContent>
                                                    <SttGrid container spacing={1}>
                                                        <SttGrid item xs={12} sm={4} md={2}>
                                                            <Field name={NOME_PACIENTE}>
                                                                {({
                                                                    field
                                                                }) => (
                                                                    <SttInput
                                                                        {...field}
                                                                        label={strings.nome}
                                                                    />
                                                                )}
                                                            </Field>
                                                        </SttGrid>

                                                        <SttGrid item xs={12} sm={4} md={2}>
                                                            <FastField name={CPF_PACIENTE}>
                                                                {({
                                                                    field,
                                                                    meta
                                                                }) => (
                                                                    <SttMaskedInput
                                                                        {...field}
                                                                        mask="cpf"
                                                                        label={strings.cpf}
                                                                        error={meta.touched && meta.error ? true : false}
                                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    />
                                                                )}
                                                            </FastField>
                                                        </SttGrid>
                                                        <SttGrid item xs={12} sm={4} md={2}>
                                                            <FastField name={CNS_PACIENTE}>
                                                                {({
                                                                    field,
                                                                    meta,
                                                                }) => (
                                                                    <SttMaskedInput
                                                                        {...field}
                                                                        mask="cns"
                                                                        label={strings.cns}
                                                                        error={meta.touched && meta.error ? true : false}
                                                                        helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    />
                                                                )}
                                                            </FastField>
                                                        </SttGrid>
                                                    </SttGrid>
                                                </SttCardContent>
                                            </SttCard>
                                        </SttGrid>
                                    </SttGrid>

                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                nomarginleft="true"
                                                onClick={resetPageCount}
                                            >
                                                {strings.pesquisar}
                                            </SttButton>
                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    reset();
                                                    resetForm({
                                                        values: filtrosIniciais
                                                    });
                                                    callbackBusca(null);
                                                }}
                                            >
                                                {strings.limpar}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                                <Alerta
                                    open={mostrarAlerta}
                                    title={tituloAlerta}
                                    message={mensagemAlerta}
                                    type={tipoAlerta}
                                    options={opcoesAlerta}
                                    onClose={handleCloseAlerta}
                                />
                            </>
                        )
                    }
                }
            </Formik>
        </>
    );
};

FormPesquisa.propTypes = {
    user: PropTypes.object.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    buscar: PropTypes.bool.isRequired,
    tipoAba: PropTypes.string.isRequired,
    idAba: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    filtros: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    resetPageCount: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.exameReducer.filtros
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        reset: () => dispatch(resetAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPesquisa);