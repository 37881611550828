import React, { useEffect, useState, lazy, memo, useContext, Suspense } from 'react';
import {
    SttTabs,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: theme.spacing(1.5)
    },
}));

const VisualizacaoDescritorRede = lazy(() => import('../../componentes/administrativo/visualizacao-descritor-rede'));
const MotivosPriorizacaoRede = lazy(() => import('../../componentes/administrativo/motivos-priorizacao-rede'));

const VisualizacaoDescritorRedeSuspense = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary"/>}>
            <VisualizacaoDescritorRede {...props} />
        </Suspense>
    )
});

const MotivosPriorizacaoPorRede = memo((props) => {
    return (
        <Suspense fallback={<SttCircularProgress color="primary"/>}>
            <MotivosPriorizacaoRede {...props} />
        </Suspense>
    )
});

const Administrativo = (props) => {    
    const { user, reset } = props;
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const [abas, setAbas] = useState([]);
    const [abaAtiva, setAbaAtiva] = useState('0');

    useEffect(() => {
        let dadosAbas = [
            {
                titulo: strings.visualizacaoDescritorRede,
                conteudo: VisualizacaoDescritorRedeSuspense,
                permanente: true,
                key: 'VisualizacaoDescritorRedeSuspense '
            },
            {
                titulo: strings.motivosPriorizacaoRede,
                conteudo: MotivosPriorizacaoPorRede,
                permanente: true,
                key: 'MotivosPriorizacaoPorRede '
            }
        ];

        setAbas(dadosAbas);
    }, [user]);
     
    return (
        <SttTabs abas={abas}
        className={classes.wrapper}
        abaAtiva={abaAtiva}
        />
    );
}

export default Administrativo;