import ConstantesCarregando from '../../constantes/carregando';

const { REDUCER_TYPE } = ConstantesCarregando;

const INITIAL_STATE = {
    parametros: {
        open: false,
        titulo: null
    }
};

function carregandoReducer(state, action) {
    if(!state) {
        state = INITIAL_STATE;
    }
    
    const { type, payload } = action;

    if(type === REDUCER_TYPE.MOSTRAR_CARREGANDO){
        if(!payload.open) {
            return {
                ...state,
                parametros: INITIAL_STATE
            }; 
        }

        return {
            ...state,
            parametros: payload
        };
    }
    return state;
}

export default carregandoReducer;