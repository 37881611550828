import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SttHeading from '@stt-componentes/core/dist/lib/SttTypography/SttHeading';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { connect } from 'react-redux';
import {
    SttText,
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

const Manutencao = (props) => {
    const classes = useStyles();
    const { message } = props;

    return (
        <div className={classes.root}>
            <SttHeading variant="h1">
                <ErrorOutlineIcon />
                Em Manutenção
            </SttHeading>
            <SttText>O módulo de eletrocardiografia está em manutenção. Em breve o sistema retornará ao seu funcionamento normal.</SttText>
            {
                global.gConfig.manutencao_previsao !== '' &&
                <SttText>Período de manutenção previsto: {global.gConfig.manutencao_previsao}</SttText>
            }
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        message: state.index.message,
    };
};

export default connect(mapStateToProps, null)(Manutencao);
