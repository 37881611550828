import React, { useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';

import {
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTableSortLabel,
    SttTablePagination,
    SttCircularProgress,
    SttSeeButton,
    SttHidden,
    SttText
} from '@stt-componentes/core';

import Form from './form';
import Detalhes from './detalhes';
import { Util } from '@stt-utilitarios/core';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    }
}));

const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        DATA: 'data',
        SOLICITANTE: 'nome_solicitante',
        PACIENTE: 'nome_paciente',
        CPF_PACIENTE: 'cpf',
        CNS_PACIENTE: 'cns',
        CIDADE_PROCEDENCIA: 'nome_cidade_estado'
    }
};

const PesquisaSolicitacoes = (props) => {
    const { strings } = props;
    const classes = useStyles();

    const [solicitacoes, setSolicitacoes] = useState([]);
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [page, setPage] = useState(global.gConfig.pagination.start);
    const [count, setCount] = useState(global.gConfig.pagination.count);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [order, setOrder] = useState(ORDENACAO.DIRECAO.ASC);
    const [orderBy, setOrderBy] = useState(null);
    const [buscar, setBuscar] = useState(false);
    const [idSolicitacaoSelecionada, setIdSolicitacaoSelecionada] = useState(null);

    const resetPageOrder = () => {
        setPage(global.gConfig.pagination.start);
        setCount(global.gConfig.pagination.count);
        setOrder(ORDENACAO.DIRECAO.ASC);
        setOrderBy(null);
    };

    const handleClickSort = (campo) => {
        const isAsc = (orderBy === campo) && (order === ORDENACAO.DIRECAO.ASC);
        const newOrder = isAsc ? ORDENACAO.DIRECAO.DESC : ORDENACAO.DIRECAO.ASC;
        setOrder(newOrder);
        setOrderBy(campo);
        setBuscar(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = event => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.start);
        setBuscar(true);
    };

    const callbackBusca = (dados) => {
        if (dados) {
            setTotalRegistros(parseInt(dados.totalRegistros));
            setSolicitacoes(dados.itens);
        } else {
            setTotalRegistros(0);
            setSolicitacoes([]);
        }
        setBuscar(false);
    };

    const handleClickVoltar = (atualizarBusca) => {
        setIdSolicitacaoSelecionada(null);
        if (atualizarBusca) {
            setBuscar(true);
        }
    };

    return (
        <>
            {
                !idSolicitacaoSelecionada
                    ?
                    (
                        <>
                            <Form
                                strings={strings}
                                callbackBusca={callbackBusca}
                                callbackAndamento={setBuscaEmAndamento}
                                page={page}
                                count={count}
                                orderBy={orderBy}
                                order={order}
                                buscar={buscar}
                                resetPageOrder={resetPageOrder}
                            />
                            <div className={classes.tableWrapper}>
                                <SttTable>
                                    <SttTableHead>
                                        <SttTableRow>
                                            <SttHidden smDown>
                                                <SttTableCell width="10%" sortDirection={orderBy === ORDENACAO.CAMPOS.DATA ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.DATA}
                                                        direction={orderBy === ORDENACAO.CAMPOS.DATA ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.DATA)}
                                                    >
                                                        {strings.dataSolicitacao}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="19%" sortDirection={orderBy === ORDENACAO.CAMPOS.SOLICITANTE ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.SOLICITANTE}
                                                        direction={orderBy === ORDENACAO.CAMPOS.SOLICITANTE ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.SOLICITANTE)}
                                                    >
                                                        {strings.medicoSolicitante}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="22%" sortDirection={orderBy === ORDENACAO.CAMPOS.PACIENTE ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.PACIENTE}
                                                        direction={orderBy === ORDENACAO.CAMPOS.PACIENTE ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.PACIENTE)}
                                                    >
                                                        {strings.paciente}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="12%" sortDirection={orderBy === ORDENACAO.CAMPOS.CPF_PACIENTE ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.CPF_PACIENTE}
                                                        direction={orderBy === ORDENACAO.CAMPOS.CPF_PACIENTE ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CPF_PACIENTE)}
                                                    >
                                                        {strings.cpfPaciente}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="12%" sortDirection={orderBy === ORDENACAO.CAMPOS.CNS_PACIENTE ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.CNS_PACIENTE}
                                                        direction={orderBy === ORDENACAO.CAMPOS.CNS_PACIENTE ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CNS_PACIENTE)}
                                                    >
                                                        {strings.cnsPaciente}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="20%" sortDirection={orderBy === ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA ? order : false}>
                                                    <SttTableSortLabel
                                                        active={orderBy === ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA}
                                                        direction={orderBy === ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA ? order : ORDENACAO.DIRECAO.ASC}
                                                        onClick={() => handleClickSort(ORDENACAO.CAMPOS.CIDADE_PROCEDENCIA)}
                                                    >
                                                        {strings.cidadeUf}
                                                    </SttTableSortLabel>
                                                </SttTableCell>
                                                <SttTableCell width="5%">
                                                    {strings.visualizar}
                                                </SttTableCell>
                                            </SttHidden>
                                            <SttHidden mdUp>
                                                <SttTableCell width="100%" colSpan="2">Solicitações de exames</SttTableCell>
                                            </SttHidden>
                                        </SttTableRow>
                                    </SttTableHead>
                                    <SttTableBody>
                                        {
                                            buscaEmAndamento
                                                ?
                                                <SttTableRow key={-1}>
                                                    <SttTableCell colSpan={7}
                                                        align="center">
                                                        <SttCircularProgress />
                                                    </SttTableCell>
                                                </SttTableRow>
                                                :
                                                (
                                                    solicitacoes.length === 0 ?
                                                        <SttTableRow key={-1}>
                                                            <SttTableCell colSpan={7}
                                                                align="center">
                                                                {strings.nenhumRegistroEncontrado}
                                                            </SttTableCell>
                                                        </SttTableRow>
                                                        :
                                                        solicitacoes.map((row, index) => (
                                                            <SttTableRow key={index}>
                                                                <SttHidden smDown>
                                                                    <SttTableCell>{row.data_formatada}</SttTableCell>
                                                                    <SttTableCell>{row.nome_solicitante}</SttTableCell>
                                                                    <SttTableCell>{row.nome_paciente}</SttTableCell>
                                                                    <SttTableCell>{row.cpf ? Util.util.adicionarMascara(row.cpf.padStart(11, '0'), '000.000.000-00') : ''}</SttTableCell>
                                                                    <SttTableCell>{row.cartao_sus ? Util.util.adicionarMascara(row.cartao_sus.padStart(15, '0'), '000 0000 0000 0000') : ''}</SttTableCell>
                                                                    <SttTableCell>{row.nome_cidade_estado}</SttTableCell>
                                                                </SttHidden>
                                                                <SttHidden mdUp>
                                                                    <SttTableCell width="96%">
                                                                        <SttText size="small">
                                                                            <b>{strings.data}:</b> {row.data_formatada}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.medicoSolicitante}:</b> {row.nome_solicitante}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.paciente}:</b> {row.nome_paciente}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.cpfPaciente}:</b> {row.cpf ? Util.util.adicionarMascara(row.cpf.padStart(11, '0'), '000.000.000-00') : ''}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.cnsPaciente}:</b> {row.cartao_sus ? Util.util.adicionarMascara(row.cartao_sus.padStart(15, '0'), '000 0000 0000 0000') : ''}
                                                                        </SttText>
                                                                        <SttText size="small">
                                                                            <b>{strings.cidadeUf}:</b> {row.nome_cidade_estado}
                                                                        </SttText>
                                                                    </SttTableCell>
                                                                </SttHidden>
                                                                <SttTableCell align="center">
                                                                    <SttSeeButton
                                                                        id={`btn-ver-solicitacao-${index}`}
                                                                        onClick={() => {
                                                                            setIdSolicitacaoSelecionada(row.id);
                                                                        }}
                                                                    />
                                                                </SttTableCell>
                                                            </SttTableRow>
                                                        ))
                                                )
                                        }
                                    </SttTableBody>
                                </SttTable>
                            </div>
                            {
                                solicitacoes.length > 0 ?
                                    (
                                        <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                                            component="div"
                                            count={totalRegistros}
                                            rowsPerPage={count}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            labelRowsPerPage={strings.linhasPorPagina}
                                            labelDisplayedRows={(params) => `${params.from}-${params.to} ${strings.de} ${params.count}`}
                                        />
                                    ) : null
                            }
                        </>
                    )
                    : <Detalhes strings={strings} id={idSolicitacaoSelecionada} voltar={handleClickVoltar} />
            }
        </>
    )

}

PesquisaSolicitacoes.propTypes = {
    strings: PropTypes.object.isRequired
};

export default PesquisaSolicitacoes;