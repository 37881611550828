import { combineReducers } from 'redux';
import alertaReducer from './reducers/alertaReducer';
import envioImagensReducer from './reducers/envioImagensReducer';
import exameReducer from './reducers/exameReducer';
import laudoReducer from './reducers/laudoReducer';
import carregandoReducer from './reducers/carregandoReducer';

import TYPES from './types';

const INITIAL_STATE = {
    isAuthenticated: false,
    user: {
        nome: '',
        email: '',
        foto: '',
        message:''
    }
};

const index = (state = INITIAL_STATE, action) => {

    const { type, payload } = action;
    switch (type) {
        case TYPES.SET_AUTHENTICATED:
            return {
                ...state,
                isAuthenticated: payload.authenticated
            }

        case TYPES.SET_USER:
            return {
                ...state,
                user: payload.user
            }

        case TYPES.LOGOUT:
            return INITIAL_STATE;
            
        case TYPES.SET_MESSAGE:
            return {
                ...state,
                message: payload.message
            }

        default:
            return state;
    }
}

export const Reducers = combineReducers({
    index,
    alertaReducer,
    envioImagensReducer,
    exameReducer,
    laudoReducer,
    carregandoReducer
});
