import React, { useState, useRef, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setParametrosPesquisaLG as setParametrosPesquisaAction, resetLG as resetAction } from '../../redux/actions/exame';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useNavigate } from "react-router-dom";
import { useMoment } from '../../hooks';
import { Formik, Field } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../request';
import validationSchema from '../exame/validationSchema';
import { estadoInicialForm } from '../exame/estadoInicialForm';
import { temPerfilRBAC } from '../../security/acl';
import {
    UF,
    MUNICIPIO,
    INSTITUICAO,
    REDE,
    FUNCIONARIO_ENVIO,
    REQUISICAO,
    NOME_PACIENTE,
    DATA_EXAME_DE,
    DATA_EXAME_ATE,
    DATA_LAUDO_DE,
    DATA_LAUDO_ATE,
    PROTOCOLO,
    NUMERO_EXAME,
    SITUACAO_LAUDO_GRAVE
} from '../exame/fieldNames';
import { MODALIDADE, PERFIL, SITUACOES_LAUDO_GRAVE } from '../../common/Constants';
import {
    SttTable,
    SttTableHead,
    SttDatePicker,
    SttButton,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttTablePagination,
    SttCircularProgress,
    SttMenuItem,
    SttDivider,
    SttGrid,
    SttTranslateHook,
    SttInputProtocol,
    SttNumberInput,
    SttAutocomplete,
    SttInput
} from '@stt-componentes/core';
import { Chip } from '@material-ui/core';
import { ListaExames } from '@stt-utilitarios/core';

const useStyles = makeStyles(theme => ({
    tableWrapper: {
        marginTop: theme.spacing(3)
    }
}));

const LaudosGraves = (props) => {
    const { user, setParametrosPesquisa, reset, filtros } = props;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const navigate = useNavigate();
    const moment = useMoment();
    const schema = validationSchema(strings);
    const filtrosIniciais = estadoInicialForm(global.gConfig);
    const formRef = useRef();

    const [buscar, setBuscar] = useState(true);
    const [exames, setExames] = useState([]);
    const [buscaEmAndamento, setBuscaEmAndamento] = useState(false);
    const [page, setPage] = useState(filtros.page);
    const [count, setCount] = useState(filtros.count);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [ufs, setUfs] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [instituicoes, setInstituicoes] = useState([]);
    const [redes, setRedes] = useState([]);

    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
    const ECG_API_BASE_URL = global.gConfig.url_base_eletro;

    const visualizarExame = (id) => {
        navigate('/exames/visualizar', { state: { exame: id } });
    }

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    const resetPageCount = () => {
        setPage(global.gConfig.pagination.page);
        setCount(global.gConfig.pagination.count);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        setBuscar(true);
    };

    const handleChangeRowsPerPage = event => {
        setCount(event.target.value);
        setPage(global.gConfig.pagination.page);
        setBuscar(true);
    };

    

    const callbackBusca = (dados) => {

        if (dados) {
            setTotalRegistros(parseInt(dados.totalRegistros));
            setExames(dados.itens);
        } else {
            setTotalRegistros(0);
            setExames([]);
        }
        setBuscar(false);
    }

    useEffect(() => {
        let url = `${UTILITARIOS_API_BASE_URL}/localizacao/estado`;
        if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) {
            const perfil = user.perfisRBAC.filter(p => p.identificador === PERFIL.ADMINISTRADOR_ESTADUAL);
            const estados = perfil[0].estados;
            const idsUf = estados.map(e => e.id);
            url = `${url}?ids=${idsUf.join(',')}`;
        }

        axios
            .get(url, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setUfs(itens);
                }
            })
            .catch(err => console.log(err));

        axios
            .get(`${UTILITARIOS_API_BASE_URL}/rede?modalidade=${MODALIDADE.SIGLA}`, { headers: getHeaders() })
            .then((response) => {
                if (response.data) {
                    const { itens } = response.data.data;
                    setRedes(itens);
                }
            })
            .catch(err => console.log(err));
    }, []);

    const handleChangeUf = (uf) => {
        if (uf) {
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${uf.id}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        setMunicipios(itens);
                    } else {
                        setMunicipios([]);
                    }
                })
                .catch(err => console.log(err));
        }
    }

    const handleChangeMunicipio = (municipio) => {
        if (municipio) {
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/instituicao-equipamento?municipio=${municipio.id}&modalidade=${MODALIDADE.SIGLA}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        setInstituicoes(itens);
                    } else {
                        setInstituicoes([]);
                    }
                })
                .catch(err => console.log(err));
        }
    }

    const estruturarFiltros = (dados) => {
        let filtrosPesquisa = {
            start: dados.start,
            count: dados.count
        };
        if (dados['data-exame-de']) {
            filtrosPesquisa['data-exame-de'] = moment(dados['data-exame-de']).format('YYYY-MM-DD');
        }
        if (dados['data-exame-ate']) {
            filtrosPesquisa['data-exame-ate'] = moment(dados['data-exame-ate']).format('YYYY-MM-DD');
        }
        if (dados.uf) {
            filtrosPesquisa.uf = dados.uf.id;
        }
        if (dados.municipio) {
            filtrosPesquisa.municipio = dados.municipio.id;
        }
        if (dados.instituicao) {
            filtrosPesquisa.instituicao = dados.instituicao.id;
        }
        if (dados['funcionario-envio']) {
            filtrosPesquisa['funcionario-envio'] = dados['funcionario-envio'];
        }
        if (dados.requisicao) {
            filtrosPesquisa.requisicao = dados.requisicao;
        }
        if (dados.paciente) {
            filtrosPesquisa.paciente = dados.paciente;
        }
        if (dados.rede) {
            filtrosPesquisa.rede = dados.rede.id;
        }
        if (dados['data-laudo-de']) {
            filtrosPesquisa['data-laudo-de'] = moment(dados['data-laudo-de']).format('YYYY-MM-DD');
        }
        if (dados['data-laudo-ate']) {
            filtrosPesquisa['data-laudo-ate'] = moment(dados['data-laudo-ate']).format('YYYY-MM-DD');
        }
        if (dados.protocolo) {
            filtrosPesquisa.protocolo = dados.protocolo;
        }
        if (dados['numero-exame']) {
            filtrosPesquisa['numero-exame'] = dados['numero-exame'];
        }
        if(dados.situacaoLaudoGrave) {
            filtrosPesquisa['situacao-laudo-grave'] = dados.situacaoLaudoGrave.identificador;
        }
        return filtrosPesquisa;
    }

    return (
        <>
            <SttGrid container spacing={3}>
                <SttGrid item xs={12}>
                    <Formik
                        innerRef={formRef}
                        initialValues={filtros}
                        validationSchema={schema}
                        onSubmit={(data, { setSubmitting }) => {

                            data.start = page * count;
                            data.count = count;
                            data.page = page;

                            setSubmitting(true);
                            setBuscaEmAndamento(true);
                            // Salva os parâmetros da pesquisa para manter estado do 
                            // formulário durante a navegação entre páginas
                            setParametrosPesquisa(data);

                            let params = estruturarFiltros(data);
                            axios
                                .get(`${ECG_API_BASE_URL}/laudos-graves`, { params: params, headers: getHeaders() })
                                .then((response) => {
                                    const dados = response.data.data;
                                    callbackBusca(dados);
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                                .finally(() => {
                                    setSubmitting(false);
                                    setBuscaEmAndamento(false);
                                });
                        }}
                    >
                        {
                            ({
                                values,
                                isSubmitting,
                                handleSubmit,
                                resetForm
                            }) => {
                                return (
                                    <>
                                        <form onSubmit={handleSubmit} noValidate>
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12} sm={6} md={2} lg={2}>
                                                    <Field name={DATA_EXAME_DE}>
                                                        {({
                                                            field: { name, value, },
                                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                                            meta
                                                        }) => {
                                                            let erroAtual = meta.error;
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataInicialExame}
                                                                    inputprops={{
                                                                        name: name
                                                                    }}
                                                                    disableFuture
                                                                    maxDate={new Date()}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    onError={error => {
                                                                        if (error && error !== erroAtual) {
                                                                            setFieldError(DATA_EXAME_DE, error);
                                                                        }
                                                                    }}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_EXAME_DE, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={date => setFieldValue(DATA_EXAME_DE, date, true)}
                                                                    onClose={() => setFieldTouched(DATA_EXAME_DE, true)}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={2} lg={2}>
                                                    <Field name={DATA_EXAME_ATE}>
                                                        {({
                                                            field: { name, value, },
                                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                                            meta
                                                        }) => {
                                                            let erroAtual = meta.error;
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataFinalExame}
                                                                    inputprops={{
                                                                        name: name
                                                                    }}
                                                                    disableFuture
                                                                    maxDate={new Date()}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    onError={error => {
                                                                        if (error && error !== erroAtual) {
                                                                            setFieldError(DATA_EXAME_ATE, error);
                                                                        }

                                                                    }}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_EXAME_ATE, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={date => setFieldValue(DATA_EXAME_ATE, date, true)}
                                                                    onClose={() => setFieldTouched(DATA_EXAME_ATE, true)}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={2} lg={2}>
                                                    <Field name={DATA_LAUDO_DE}>
                                                        {({
                                                            field: { name, value, },
                                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                                            meta
                                                        }) => {
                                                            let erroAtual = meta.error;
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataInicialLaudo}
                                                                    inputprops={{
                                                                        name: name
                                                                    }}
                                                                    disableFuture
                                                                    maxDate={new Date()}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    onError={error => {
                                                                        if (error && error !== erroAtual) {
                                                                            setFieldError(DATA_LAUDO_DE, error);
                                                                        }
                                                                    }}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_LAUDO_DE, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={date => setFieldValue(DATA_LAUDO_DE, date, true)}
                                                                    onClose={() => setFieldTouched(DATA_LAUDO_DE, true)}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={2} lg={2}>
                                                    <Field name={DATA_LAUDO_ATE}>
                                                        {({
                                                            field: { name, value, },
                                                            form: { setFieldValue, setFieldError, setFieldTouched },
                                                            meta
                                                        }) => {
                                                            let erroAtual = meta.error;
                                                            return (
                                                                <SttDatePicker
                                                                    label={strings.dataFinalLaudo}
                                                                    inputprops={{
                                                                        name: name
                                                                    }}
                                                                    disableFuture
                                                                    maxDate={new Date()}
                                                                    error={meta.touched && meta.error ? true : false}
                                                                    onError={error => {
                                                                        if (error && error !== erroAtual) {
                                                                            setFieldError(DATA_LAUDO_ATE, error);
                                                                        }

                                                                    }}
                                                                    value={value}
                                                                    onBlur={() => {
                                                                        setFieldTouched(DATA_LAUDO_ATE, true);
                                                                    }}
                                                                    helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                    onChange={date => setFieldValue(DATA_LAUDO_ATE, date, true)}
                                                                    onClose={() => setFieldTouched(DATA_LAUDO_ATE, true)}
                                                                />
                                                            );
                                                        }}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={2} lg={2}>
                                                    <Field name={PROTOCOLO}>
                                                        {({
                                                            field
                                                        }) => (
                                                            <SttInputProtocol
                                                                label={strings.protocolo}
                                                                {...field}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={6} md={2} lg={2}>
                                                    <Field name={NUMERO_EXAME}>
                                                        {({
                                                            field
                                                        }) => (
                                                            <SttNumberInput
                                                                label={strings.numeroExame}
                                                                {...field}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={3} md={2} lg={1} xl={1}>
                                                    <Field name={UF}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            form: { values, setFieldValue }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.uf
                                                                }}
                                                                getOptionLabel={option => option && option.sigla || ''}
                                                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                                options={ufs}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    setFieldValue(UF, item || null);
                                                                    setFieldValue(MUNICIPIO, null);
                                                                    handleChangeUf(item);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={4} md={4} lg={3} xl={2}>
                                                    <Field name={MUNICIPIO}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            form: { setFieldValue }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.municipio
                                                                }}
                                                                getOptionLabel={option => option && option.nome || ''}
                                                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                                options={municipios}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    setFieldValue(MUNICIPIO, item || null);
                                                                    setFieldValue(INSTITUICAO, null);
                                                                    handleChangeMunicipio(item);
                                                                }}
                                                                disabled={!values[UF]}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={5} md={6} lg={5} xl={2}>
                                                    <Field name={INSTITUICAO}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            form: { setFieldValue }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.instituicao
                                                                }}
                                                                getOptionLabel={option => option && option.nome || ''}
                                                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                                options={instituicoes}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    setFieldValue(INSTITUICAO, item || null);
                                                                }}
                                                                disabled={!values[MUNICIPIO]}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={4} md={3} lg={3} xl={2}>
                                                    <Field name={REDE}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            form: { setFieldValue }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.rede
                                                                }}
                                                                getOptionLabel={option => option && option.descricao || ''}
                                                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                                options={redes}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    setFieldValue(REDE, item || null);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={5} md={3} lg={5} xl={2}>
                                                    <Field name={FUNCIONARIO_ENVIO}>
                                                        {({
                                                            field
                                                        }) => (
                                                            <SttInput
                                                                {...field}
                                                                label={strings.responsavelEnvio}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={3} md={2} lg={2} xl={1}>
                                                    <Field name={REQUISICAO}>
                                                        {({
                                                            field
                                                        }) => (
                                                            <SttInput
                                                                {...field}
                                                                label={strings.requisicao}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={5} md={4} lg={5} xl={2}>
                                                    <Field name={NOME_PACIENTE}>
                                                        {({
                                                            field
                                                        }) => (
                                                            <SttInput
                                                                {...field}
                                                                label={strings.paciente}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                                <SttGrid item xs={12} sm={3} lg={2}>
                                                    <Field name={SITUACAO_LAUDO_GRAVE}>
                                                        {({
                                                            field: { name, value, onBlur },
                                                            form: { setFieldValue }
                                                        }) => (
                                                            <SttAutocomplete
                                                                inputprops={{
                                                                    name: name,
                                                                    label: strings.situacao
                                                                }}
                                                                getOptionLabel={option => option && option.descricao || ''}
                                                                getOptionSelected={(option, val) => option && val && option.identificador === val.identificador}
                                                                options={SITUACOES_LAUDO_GRAVE}
                                                                value={value}
                                                                onBlur={onBlur}
                                                                onChange={(e, item) => {
                                                                    setFieldValue(SITUACAO_LAUDO_GRAVE, item || null);
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </SttGrid>
                                            </SttGrid>
                                            <SttGrid container spacing={3}>
                                                <SttGrid item xs={12}>
                                                    <SttButton
                                                        type="submit"
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        nomarginleft="true"
                                                        onClick={resetPageCount}
                                                    >
                                                        {strings.pesquisar}
                                                    </SttButton>
                                                    <SttButton
                                                        type="button"
                                                        variant="outlined"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            reset();
                                                            resetPageCount();
                                                            resetForm({
                                                                values: filtrosIniciais
                                                            });
                                                            callbackBusca(null);
                                                        }}
                                                    >
                                                        {strings.limpar}
                                                    </SttButton>
                                                </SttGrid>
                                            </SttGrid>
                                        </form>
                                    </>
                                )
                            }
                        }
                    </Formik>
                </SttGrid>
                <SttDivider />
                <SttGrid item xs={12}>
                    <div className={classes.tableWrapper}>
                        <SttTable>
                            <SttTableHead>
                                <SttTableRow>
                                    <SttTableCell>
                                        {strings.dataExame}
                                    </SttTableCell>
                                    <SttTableCell>
                                        {`${strings.municipio}/${strings.uf}`}
                                    </SttTableCell>
                                    <SttTableCell>
                                        {strings.instituicao}
                                    </SttTableCell>
                                    <SttTableCell>
                                        {strings.tecnico}
                                    </SttTableCell>
                                    <SttTableCell>
                                        {strings.requisicao}
                                    </SttTableCell>
                                    <SttTableCell>
                                        {strings.paciente}
                                    </SttTableCell>
                                    <SttTableCell>
                                        {strings.situacao}
                                    </SttTableCell>
                                    <SttTableCell align="center">
                                        {strings.ver}
                                    </SttTableCell>
                                </SttTableRow>
                            </SttTableHead>
                            <SttTableBody>
                                {
                                    buscaEmAndamento
                                        ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell colSpan={9} align="center">
                                                <SttCircularProgress />
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        (
                                            exames.length === 0 ?
                                                <SttTableRow key={-1}>
                                                    <SttTableCell colSpan={9} align="center">
                                                        {strings.nenhumRegistroEncontrado}
                                                    </SttTableCell>
                                                </SttTableRow>
                                                :
                                                exames.map((row, index) => (
                                                    <SttTableRow key={index}>
                                                        <SttTableCell>{`${row.data_formatada} ${global.gConfig.sufixo_data_hora}`}</SttTableCell>
                                                        <SttTableCell>{row.cidadeuf}</SttTableCell>
                                                        <SttTableCell>{row.nome_instituicao}</SttTableCell>
                                                        <SttTableCell>{row.nome_funcionario_envio}</SttTableCell>
                                                        <SttTableCell>{row.requisicao}</SttTableCell>
                                                        <SttTableCell>{row.nome_paciente}</SttTableCell>
                                                        <SttTableCell>
                                                            <Chip
                                                                style={{ backgroundColor: ListaExames.getCorLegendaLaudoGrave(row) }}
                                                                label={row.situacao}
                                                                size="small" />
                                                        </SttTableCell>
                                                        <SttTableCell align="center">
                                                            <SttMenuItem onClick={() => visualizarExame(row.id)}>
                                                                <VisibilityIcon />
                                                            </SttMenuItem>
                                                        </SttTableCell>
                                                    </SttTableRow>
                                                ))
                                        )
                                }
                            </SttTableBody>
                        </SttTable>
                    </div>
                </SttGrid>
            </SttGrid>
            {
                exames.length > 0 ?
                    (
                        <>
                            <SttTablePagination rowsPerPageOptions={[10, 20, 40]}
                                component="div"
                                count={totalRegistros}
                                rowsPerPage={count}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                labelRowsPerPage={strings.linhasPorPagina}
                                labelDisplayedRows={(params) => `${params.from}-${params.to} ${strings.de} ${params.count}`}
                            />
                        </>
                    ) : null
            }
        </>
    );
};

LaudosGraves.propTypes = {
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        filtros: state.exameReducer.filtrosLG
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        reset: () => dispatch(resetAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LaudosGraves);