import { TIPO_EXAME } from '../../common/Constants';

const initialValuesVazios = {
  tipoExame: TIPO_EXAME.ELETROCARDIOGRAFIA,
  paciente: {
      id: '',
      cpf: '',
      cns: '',
      nome: '',
      genero: null,
      dataNascimento: null,
      municipio: null,
      uf: null,
      pais: null,
      peso: '',
      altura: '',
      profissao: '',
      cep: '',
      logradouro: '',
      bairro: '',
      tipoContato1: null,
      contato1: ''
  },
  solicitante: {
      medico: null,
      outroMedico: null,
      instituicao: null
  },
  identificacaoExame: {
      motivosSolicitacao: []
  },
  comorbidadesFatoresRisco: {
      medicamentosUso: [],
      outrosMedicamentos: '',
      fatoresRisco: []
  },
  hipoteseDiagnostica: {
      dorCardiacaIsquemica: [],
      dorCardiacaNaoIsquemica: [],
      dorNaoCardiaca: [],
      valvulopatia: [],
      cardiopatiaCongenita: '',
      outros: '',
      motivoNaoDor: [],
      observacoes: ''
  },
  cid10: [],
  investigacaoDor: {
      dorMomentoExame: 'N',
      duracaoEpisodio: '',
      dataUltimoEpisodio: null,
      duracaoUltimoEpisodio: '',
      tempoApresentaSintoma: '',
      especificacaoTempoApresentaSintoma: null,
      localizacaoIrradiacaoDor: [],
      sintomasAssociados: [],
      intensidadeDor: null,
      caracterizacaoDorToracica: [],
      classificacaoDor: null,
      tipoDor: [],
      outroTipoDor: ''
  }
};

export const initialValues = initialValuesVazios;