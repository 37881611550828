import * as yup from 'yup'

import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';

import pacienteSchema from '@stt-componentes/paciente/dist/lib/form/validationSchema';
import identificacaoExameSchema from '../../componentes/solicitacao/identificacaoExame/form/validationSchema';
import solicitanteSchema from '../../componentes/solicitacao/solicitante/validationSchema';
import comorbidadesFatoresRiscoSchema from '../../componentes/solicitacao/comorbidadesFatoresRisco/validationSchema';
import hipoteseDiagnosticaSchema from '../../componentes/solicitacao/hipoteseDiagnostica/validationSchema';
import investigacaoDorSchema from '../../componentes/solicitacao/investigacaoDor/validationSchema';

export default (strings, campos) => {

    const paciente = yup.object().shape({ [PACIENTE]: pacienteSchema(strings, campos) });
    const solicitante = solicitanteSchema(strings);
    const identificacaoExame = identificacaoExameSchema(strings);
    const comorbidadesFatoresRisco = comorbidadesFatoresRiscoSchema(strings);
    const hipoteseDiagnostica = hipoteseDiagnosticaSchema(strings);
    const investigacaoDor = investigacaoDorSchema(strings);

    const schema =  yup.object().shape({});
    
    return schema
        .concat(paciente)
        .concat(solicitante)
        .concat(identificacaoExame)
        .concat(comorbidadesFatoresRisco)
        .concat(hipoteseDiagnostica)
        .concat(investigacaoDor);
} 