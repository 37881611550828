let dataDe = new Date();
let dataAte = new Date();
dataDe.setDate(dataAte.getDate() - 10);

export const estadoInicialForm = (config) => {
    return {
        uf: null,
        municipio: null,
        instituicao: null,
        rede: null,
        'funcionario-envio': '',
        requisicao: '',
        paciente: '',
        'cns-paciente': '',
        'cpf-paciente': '',
        'data-exame-de': dataDe,
        'data-exame-ate': dataAte,
        'data-laudo-de': null,
        'data-laudo-ate': null,
        'situacao-laudo': ['N', 'S', 'T', 'E', 'A'],
        atrasado: false,
        'ultrapassou-tempo-previsto': false,
        situacao: ['V'],
        protocolo: '',
        'numero-exame': '',
        page: config?.pagination.page || 0,
        count: config?.pagination.count || 10,
        // Filtros de ordenação
        page: 0,
        count: 10,
        start: null,
        sort: null,
        direction: 'asc',
        situacaoLaudoGrave: null
    }
}