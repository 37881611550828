import ConstantesAlerta from '../../constantes/alerta';

const { REDUCER_TYPE } = ConstantesAlerta;

const STATE_MODAL = {
    parametros: {
        open: false,
        titulo: '',
        tipo: 'alert',
        mensagem: '',
        opcoes: [],
        onClose: null
    }
};

function alertaReducer(state, action) {
    if(!state) {
        state = STATE_MODAL;
    }
    
    const { type, payload } = action;

    if(type === REDUCER_TYPE.SETAR_PARAMETROS_ALERTA){
        return {
            ...state,
            parametros: payload.parametros
        };
    }

    if(type === REDUCER_TYPE.SETAR_OPEN){
        return {
            ...state,
            parametros: [STATE_MODAL.parametros, {open: payload.open}]
        };
    }
    return state;
}

export default alertaReducer;