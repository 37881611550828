import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Interno from './interno/index';
import Logout from './logout';
import Erro from './erro';

const Home = () => {
    return (
        <BrowserRouter basename={`/${global.gConfig.basename}`}>
            <Routes>
                <Route path="*" element={<Interno />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/erro" element={<Erro />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Home;
