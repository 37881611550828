import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttHeading,
    SttGrid,
    SttTranslateHook,
    SttTable,
    SttTableHead,
    SttTableBody,
    SttTableRow,
    SttTableCell,
    SttHidden,
    SttText
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    contentWrapper: {
        marginBottom: theme.spacing(2)
    },
    heading: {
        marginBottom: theme.spacing(1)
    }
}));

const DescritorSBC = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { sbcs } = props;
    const classes = useStyles();

    return (
        <>
            <SttGrid container spacing={3} className={classes.contentWrapper}>
                <SttGrid item xs={12}>
                    <SttHeading color="primary" variant="h5" className={classes.heading}>
                        {strings.descritoresSBC}
                    </SttHeading>
                    <div>
                        <SttTable>
                            <SttTableHead>
                                <SttTableRow>
                                    <SttHidden xsDown>
                                        <SttTableCell width="20%">{strings.sigla}</SttTableCell>
                                        <SttTableCell width="35%">{strings.descritor}</SttTableCell>
                                        <SttTableCell width="45%">{strings.definicaoSBC}</SttTableCell>
                                    </SttHidden>
                                </SttTableRow>
                            </SttTableHead>
                            <SttTableBody>
                                {
                                    sbcs.length === 0 ?
                                        <SttTableRow key={-1}>
                                            <SttTableCell align="center">
                                                {strings.nenhumRegistroEncontrado}
                                            </SttTableCell>
                                        </SttTableRow>
                                        :
                                        sbcs.map((row, index) => (
                                            <SttTableRow key={index}>
                                                <SttHidden xsDown>
                                                    <SttTableCell>{row.sigla}</SttTableCell>
                                                    <SttTableCell>{row.descritor}</SttTableCell>
                                                    <SttTableCell>{row.definicao_sbc}</SttTableCell>
                                                </SttHidden>
                                                <SttHidden smUp>
                                                    <SttTableCell width="96%">
                                                        <SttText size="small">
                                                            <b>{strings.sigla}:</b> {row.sigla}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.descritor}:</b> {row.descritor}
                                                        </SttText>
                                                        <SttText size="small">
                                                            <b>{strings.definicaoSBC}:</b> {row.definicao_sbc}
                                                        </SttText>
                                                    </SttTableCell>
                                                </SttHidden>
                                            </SttTableRow>
                                        ))
                                }
                            </SttTableBody>
                        </SttTable>
                    </div>
                </SttGrid>
            </SttGrid>
        </>
    );
}

export default DescritorSBC;