import * as yup from 'yup'
import {
    MOTIVOS_SOLICITACAO,
    IDENTIFICACAO_EXAME
} from './fieldNames';

export default (strings) => {
    return yup.object().shape({
        [IDENTIFICACAO_EXAME]: yup.object().shape({
            [MOTIVOS_SOLICITACAO]: yup.array().of(
                yup.object().shape({
                    id: yup.number(),
                    descricao: yup.string(),
                    tipo: yup.string()
                })
            ).min(1, strings.motivosSolicitacaoObrigatorio),
        }).required()
    });
};