import TYPES from '../types/exame';
import { estadoInicialForm } from '../../componentes/exame/estadoInicialForm';

const filtrosIniciais = estadoInicialForm();

const INITIAL_STATE = {
    filtros: filtrosIniciais,
    filtrosLG: filtrosIniciais,
    atualizarBusca: false
};

const exame = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;

    switch (type) {
        case TYPES.SET_FILTROS:
            return {
                ...state,
                filtros: payload.parametros
            }

        case TYPES.RESET:
            return {
                ...state,
                filtros: filtrosIniciais
            };

        case TYPES.SET_FILTROS_LG:
            return {
                ...state,
                filtrosLG: payload.parametros
            }

        case TYPES.RESET_LG:
            return {
                ...state,
                filtrosLG: filtrosIniciais
            };

        case TYPES.ATUALIZAR_BUSCA:
            return {
                ...state,
                atualizarBusca: payload.atualizar
            }

        default:
            return state;
    }
}

export default exame;
