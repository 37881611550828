import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom'
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { getHeaders } from '../../request';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { values } from './initialValues';
import LaudoTextual from './abaTextual';
import SttCid10 from '@stt-componentes/cid10';
import SttDecs from '@stt-componentes/decs';
import { setLaudar as setLaudarAction } from '../../redux/actions/laudo';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from '../../redux/actions/alerta';
import { setAtualizarBusca as setAtualizarBuscaAction } from '../../redux/actions/exame';
import {
    SttExpansionPanel,
    SttGrid,
    SttButton,
    SttLoading,
    SttAlerta,
    SttTranslateHook,
    SttDivider,
    SttModal
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    conteinerDecs: {
        paddingTop: theme.spacing(2)
    },
    tabs: {
        padding: '0'
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
    },
    buttonWrapper: {
        display: 'flex'
    }
}));

const FormLaudo = (props) => {
    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const {
        idExame,
        idLaudoEmissao,
        idPaciente,
        idSolicitante,
        nomeSolicitante,
        descritoresLaudo,
        setAtualizarBusca,
        setLaudar,
        callbackProximoExame,
        setParametrosAlerta,
        setOpen,
        user,
        laudoGrave
    } = props;

    const location = useLocation();
    const classes = useStyles();

    // Controles do modal de confirmação
    const [modalAberto, setModalAberto] = useState(false);
    const [laudo, setLaudo] = useState(null);

    // Controles do formulário
    const [initialValues, setInitialValues] = useState(null);
    const [erro, setErro] = useState(false);
    const [mensagemErro, setMensagemErro] = useState('');
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [contextoFabricalaudo] = useState(location.pathname === '/laudo');

    //Alerta 
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [mensagemAlerta, setMensagemAlerta] = useState(strings.mensagemAlertaCancelamento);
    const [tipoAlerta, setTipoAlerta] = useState('alert');

    // Alerta de sucesso/erro na assinatura digital do laudo
    const [alertaAssinatura, setAlertaAssinatura] = useState(false);
    const [tituloAlertaAssinatura, setTituloAlertaAssinatura] = useState('');
    const [mensagemAlertaAssinatura, setMensagemAlertaAssinatura] = useState('');
    const [tipoAlertaAssinatura, setTipoAlertaAssinatura] = useState('');
    const [textoCarregando, setTextoCarregando] = useState('');

    const handleCloseAlerta = () => {
        setErro(false);
        setMostrarAlerta(false);
    };

    const opcoesAlerta = [{
        title: strings.ok,
        onClick: handleCloseAlerta
    }];

    const inicializarFormulario = () => {
        let descritoresLaudoRevisados = descritoresLaudo;
        if (descritoresLaudoRevisados?.descritoresSbc?.length && descritoresLaudoRevisados.descritoresSbc[0].versao === 'v1') {
            let arrDescritoresExcluidos = [strings.erroDescritoresV1];
            descritoresLaudoRevisados.descritoresSbc.forEach(descritor => {
                arrDescritoresExcluidos.push(`- ${descritor.descritor}`);
            });
            let mensagem = arrDescritoresExcluidos.join('\n');
            setTipoAlerta('alert');
            setTituloAlerta(strings.atencao)
            setMensagemAlerta(mensagem);
            setMostrarAlerta(true);
            descritoresLaudoRevisados.descritoresSbc = null;
        }
        let laudoTextualGrave = laudoGrave || false
        setInitialValues(values(
            initialValues,
            idExame,
            idLaudoEmissao,
            idPaciente,
            idSolicitante,
            nomeSolicitante,
            descritoresLaudoRevisados,
            laudoTextualGrave
        ));
    };

    useEffect(() => {
        inicializarFormulario();
    }, []);

    const callbackCancelar = () => {
        setModalAberto(false);
    };

    const callbackConfirmar = () => {
        setModalAberto(false);
        if (contextoFabricalaudo) {
            callbackProximoExame();
        } else {
            const parametrosAlerta = {
                titulo: strings.sucesso,
                mensagem: strings.sucessoRelaudo,
                tipo: 'success',
                open: true,
                opcoes: [
                    {
                        title: strings.ok,
                        onClick: () => {
                            setOpen(false);
                            setLaudar(false);
                            setAtualizarBusca(true);
                        }
                    }
                ],
                onClose: () => {
                    setOpen(false);
                    setLaudar(false);
                    setAtualizarBusca(true);
                }
            };
            setParametrosAlerta(parametrosAlerta);
        }
    };

    const [secoesAbertas, setSecoesAbertas] = useState({
        'laudoTextual': true,
        'descritores': false
    });

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }
        novoSecoesAbertas[secao] = estado;
        setSecoesAbertas(novoSecoesAbertas);
    };

    const gerarResumoLaudo = (data, setSubmitting, resetForm, visualizarResumo = true) => {
        setTextoCarregando(visualizarResumo ? strings.gerandoResumoVisualizacao : strings.gerandoResumoPublicacao);
        setSubmitting((true));
        axios
            .post(`${ELETRO_API_BASE_URL}/resumo-laudo`, data, { headers: getHeaders() })
            .then((response) => {
                if(visualizarResumo) {
                    setLaudo(response.data.data);
                    setModalAberto(true);
                    return;
                }

                confirmarEnvioLaudo(data, setSubmitting, resetForm);
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        let arrMensagem = [];
                        response.data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        setTituloAlerta(response.data.message);
                    } else {
                        setTituloAlerta(strings.erro);
                    }
                } else {
                    setTituloAlerta(strings.erro);
                }
                setMensagemErro(msg);
                setErro(true);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const confirmarEnvioLaudo = (data, setSubmitting, resetForm) => {
        const EXAME_API_BASE_URL = global.gConfig.url_base_exames;
        setTextoCarregando(strings.gravandoLaudo);
        setSubmitting(true);
        
        axios
            .post(`${ELETRO_API_BASE_URL}/confirmar-laudo`, data, { headers: getHeaders() })
            .then((response) => {
                resetForm();
                callbackConfirmar();
            })
            .catch(err => {
                console.log(err);
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const { data } = response;
                        let arrMensagem = [];
                        data.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        setTituloAlerta(data.message);
                    } else {
                        setTituloAlerta(strings.erro);
                    }
                } else {
                    setTituloAlerta(strings.erro);
                }
                setMensagemErro(msg);
                setErro(true);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleClosAlertaAssinatura = () => {
        callbackConfirmar();
    };

    return (
        initialValues &&
        <>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(data, { setSubmitting, resetForm }) => {
                    gerarResumoLaudo(data, setSubmitting, resetForm, false);
                }}
            >
                {
                    ({
                        values: val,
                        errors,
                        isSubmitting,
                        submitCount,
                        setFieldValue,
                        handleSubmit,
                        setSubmitting, 
                        resetForm
                    }) => {

                        return (
                            <div>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttExpansionPanel
                                        opened={secoesAbertas['laudoTextual']}
                                        callback={estadoInterno => abrirSecao('laudoTextual', estadoInterno)}
                                        title={strings.laudoTextual}
                                        compact
                                        children={
                                            <LaudoTextual />
                                        }
                                    />
                                    <SttDivider className={classes.divider} />
                                    <SttExpansionPanel
                                        opened={secoesAbertas['descritores']}
                                        title={strings.descritores}
                                        compact
                                        callback={estadoInterno => abrirSecao('descritores', estadoInterno)}
                                        children={
                                            <>
                                                <SttGrid container spacing={3}>
                                                    <SttGrid item xs={12}>
                                                        <div className={classes.conteinerDecs}>
                                                            <SttCid10
                                                                strings={strings}
                                                                headers={getHeaders()}
                                                                multiplos
                                                                formExterno={{
                                                                    cid10: val.cid10,
                                                                    isSubmitting,
                                                                    errors,
                                                                    submitCount,
                                                                    setFieldValue
                                                                }}
                                                            />
                                                        </div>
                                                    </SttGrid>
                                                </SttGrid>
                                                <SttGrid container spacing={3}>
                                                    <SttGrid item xs={12}>
                                                        <div className={classes.conteinerDecs}>
                                                            <SttDecs
                                                                strings={strings}
                                                                multiplos
                                                                formExterno={{
                                                                    decs: val.decs,
                                                                    isSubmitting,
                                                                    errors,
                                                                    submitCount,
                                                                    setFieldValue
                                                                }}
                                                            />
                                                        </div>
                                                    </SttGrid>
                                                </SttGrid>
                                            </>
                                        }
                                    />
                                    <SttGrid container spacing={3}>
                                        <SttGrid item xs={12}>
                                            <SttButton
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                disabled={isSubmitting}
                                                nomarginleft="true"
                                            >
                                                {strings.publicar}
                                            </SttButton>

                                            <SttButton
                                                type="button"
                                                variant="outlined"
                                                color="primary"
                                                disabled={isSubmitting}
                                                onClick={() => {
                                                    gerarResumoLaudo(val, setSubmitting, resetForm);
                                                }}
                                            >
                                                {strings.visualizarResumo}
                                            </SttButton>
                                        </SttGrid>
                                    </SttGrid>
                                </form>
                                <SttModal
                                    title={strings.resumoLaudo}
                                    open={modalAberto}
                                    outModalClose={callbackCancelar}
                                    iconClose={callbackCancelar}
                                    maxWidth="lg"
                                    fullWidth={true}
                                    children={
                                        <div dangerouslySetInnerHTML={{ __html: laudo }}></div>
                                    }
                                    footer={
                                        <div>
                                            <SttButton variant="contained" color="primary" disabled={isSubmitting} onClick={() => {
                                                confirmarEnvioLaudo(val, setSubmitting, resetForm);
                                            }
                                            }>
                                                {strings.publicar}
                                            </SttButton>
                                            <SttButton variant="outlined" color="primary" onClick={callbackCancelar}>
                                                {strings.fechar}
                                            </SttButton>
                                        </div>
                                    }
                                />
                                <SttAlerta
                                    open={alertaAssinatura}
                                    title={tituloAlertaAssinatura}
                                    message={mensagemAlertaAssinatura}
                                    type={tipoAlertaAssinatura}
                                    options={[
                                        {
                                            title: strings.ok,
                                            onClick: handleClosAlertaAssinatura
                                        }
                                    ]}
                                    onClose={handleClosAlertaAssinatura}
                                />
                                <SttAlerta
                                    open={erro}
                                    title={tituloAlerta}
                                    message={mensagemErro}
                                    type="error"
                                    options={opcoesAlerta}
                                    onClose={handleCloseAlerta}
                                />
                                <SttAlerta
                                    open={mostrarAlerta}
                                    title={tituloAlerta}
                                    message={mensagemAlerta}
                                    type={tipoAlerta}
                                    options={opcoesAlerta}
                                    onClose={() => {
                                        setMostrarAlerta(false);
                                    }}
                                />
                                <SttLoading
                                    open={isSubmitting}
                                    text={textoCarregando}
                                />
                            </div>
                        )
                    }
                }
            </Formik>
        </>
    );
};

FormLaudo.propTypes = {
    strings: PropTypes.object.isRequired,
    idExame: PropTypes.number.isRequired,
    idLaudoEmissao: PropTypes.number.isRequired,
    idPaciente: PropTypes.number.isRequired,
    idSolicitante: PropTypes.number.isRequired,
    nomeSolicitante: PropTypes.string.isRequired,
    descritoresLaudo: PropTypes.array.isRequired,
    callbackProximoExame: PropTypes.func
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setAtualizarBusca: atualizar => dispatch(setAtualizarBuscaAction(atualizar)),
        setLaudar: laudar => dispatch(setLaudarAction(laudar)),
        setParametrosAlerta: parametros => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: open => dispatch(setOpenAction(open))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FormLaudo);