import React, { useState, useEffect, Fragment } from 'react';
import {
    SttGrid,
    SttInput,
    SttHeading,
    SttFormControl,
    SttFormGroup,
    SttFormControlLabel,
    SttCheckbox,
    SttFormHelperText,
    SttRadioGroup,
    SttRadioButton,
    SttAutocomplete,
} from '@stt-componentes/core';
import {
    DOR_MOMENTO_EXAME,
    CLASSIFICAR_DOR_TORACICA,
    INTENSIDADE_DOR,
    LOCALIZACAO_DOR,
    TIPO_DOR,
    CARACTERIZACAO_DOR_TORACICA,
    OUTRO_TIPO_DOR
} from './fieldNames';
import { Field, FastField, FieldArray, useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { CONSTANTES_ENVIO_EXAME_CLASSIFICAR_DOR_TORACICA, CONSTANTES_INTENSIDADE_DOR, CONSTANTES_INVESTIGACAO_DOR_DOR_MOMENTO_EXAME } from '../../../common/Constants';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 0
    }
}));

const DadosExame = ({ strings }) => {
    const classes = useStyles();
    const { values, touched, setFieldValue, errors } = useFormikContext();
    const [localizacoesDor, setLocalizacoesDor] = useState([]);
    const [tiposDor, setTiposDor] = useState([]);
    const [caracterizacaoDorToracica, setCaracterizacaoDorToracica] = useState([]);
    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;

    useEffect(() => {
        const parametros = {
            ativo: true,
            etapaFluxo: ['A', 'E']
        };

        // Tipos de dor
        axios
            .get(`${ELETRO_API_BASE_URL}/administrativo/tipo-dor`, { params: parametros, headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                if (!data.errors.length) {
                    setTiposDor(data.data.itens);
                }
            })
            .catch(err => console.log(err));

        // Localização da dor
        axios
            .get(`${ELETRO_API_BASE_URL}/administrativo/localizacao-dor`, { params: parametros, headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                if (!data.errors.length) {
                    setLocalizacoesDor(data.data.itens);
                }
            })
            .catch(err => console.log(err));

        // Caracterização da dor torárica
        axios
            .get(`${ELETRO_API_BASE_URL}/administrativo/caracterizacao-dor`, { params: parametros, headers: getHeaders() })
            .then((response) => {
                const { data } = response;
                if (!data.errors.length) {
                    setCaracterizacaoDorToracica(data.data.itens);
                }
            })
            .catch(err => console.log(err));
    }, []);

    const verificarCheckedTipoDor = (tipoDor) => {
        if (values[TIPO_DOR].find(el => el.id === tipoDor.id)) {
            return true;
        }
        return false;
    }

    const verificarCheckedCaracterizacaoDorToracica = (caracterizacaoDorToracica) => {
        if (values[CARACTERIZACAO_DOR_TORACICA].find(el => el.id === caracterizacaoDorToracica.id)) {
            return true;
        }
        return false;
    }

    const onChangeCaracterizacaoDorToracica = (dorToracica, remove) => {
        if (dorToracica.nenhuma_informacao) {
            let opcaoNenhum = caracterizacaoDorToracica.find(el => el.nenhuma_informacao);
            //Caso a caracterização da dor torácica for "Nenhum", deve-se deselecionar o resto
            setFieldValue(CARACTERIZACAO_DOR_TORACICA, [
                opcaoNenhum
            ]);
        } else {
            //Verifica se a caracterização da dor torácica "Nenhum" está selecionado, e deseleciona o mesmo
            let indexNenhum = values[CARACTERIZACAO_DOR_TORACICA].findIndex(el => el.nenhuma_informacao);

            if (indexNenhum >= 0) {
                remove(indexNenhum);
            }
        }
    }

    return (
        <Fragment>
            <SttGrid container spacing={3} className={classes.container}>
                <SttGrid item xs={12} sm={6} md={4}>
                    <SttHeading required variant="h4" color="primary">{strings.dorMomentoExame}</SttHeading>
                    <FastField name={DOR_MOMENTO_EXAME}>
                        {({
                            field,
                            meta,
                        }) => (
                            <div>
                                <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                                    <SttRadioGroup
                                        row
                                        onChange={() => {
                                            setFieldValue(CLASSIFICAR_DOR_TORACICA, CONSTANTES_ENVIO_EXAME_CLASSIFICAR_DOR_TORACICA.NAO);
                                            setFieldValue(INTENSIDADE_DOR, null);
                                            setFieldValue(LOCALIZACAO_DOR, []);
                                            setFieldValue(TIPO_DOR, []);
                                            setFieldValue(OUTRO_TIPO_DOR, '');
                                            setFieldValue(CARACTERIZACAO_DOR_TORACICA, []);
                                        }}
                                    >
                                        <SttFormControlLabel
                                            {...field}
                                            control={
                                                <SttRadioButton
                                                    type="radio"
                                                    value={CONSTANTES_INVESTIGACAO_DOR_DOR_MOMENTO_EXAME.NAO}
                                                    color="primary"
                                                    checked={field.value === CONSTANTES_INVESTIGACAO_DOR_DOR_MOMENTO_EXAME.NAO}
                                                />
                                            }
                                            label={strings.nao}
                                        />
                                        <SttFormControlLabel
                                            {...field}
                                            control={
                                                <SttRadioButton
                                                    type="radio"
                                                    value={CONSTANTES_INVESTIGACAO_DOR_DOR_MOMENTO_EXAME.SIM}
                                                    color="primary"
                                                    checked={field.value === CONSTANTES_INVESTIGACAO_DOR_DOR_MOMENTO_EXAME.SIM}
                                                />
                                            }
                                            label={strings.sim}
                                        />
                                        {
                                            meta.touched && meta.error &&
                                            <SttFormHelperText error>
                                                {meta.error}
                                            </SttFormHelperText>
                                        }
                                    </SttRadioGroup>
                                </SttFormControl>
                            </div>
                        )}
                    </FastField>
                </SttGrid>

                {
                    values[DOR_MOMENTO_EXAME] === CONSTANTES_INVESTIGACAO_DOR_DOR_MOMENTO_EXAME.SIM &&
                    <SttGrid item xs={12} sm={6} md={4}>
                        <SttHeading required variant="h4" color="primary">{strings.envioExameClassificarDorToracica}</SttHeading>
                        <FastField name={CLASSIFICAR_DOR_TORACICA}>
                            {({
                                field,
                                meta,
                            }) => (
                                <div>
                                    <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                                        <SttRadioGroup
                                            row
                                            onChange={() => {
                                                setFieldValue(INTENSIDADE_DOR, null);
                                                setFieldValue(LOCALIZACAO_DOR, []);
                                                setFieldValue(TIPO_DOR, []);
                                                setFieldValue(OUTRO_TIPO_DOR, '');
                                                setFieldValue(CARACTERIZACAO_DOR_TORACICA, []);
                                            }}
                                        >
                                            <SttFormControlLabel
                                                {...field}
                                                control={
                                                    <SttRadioButton
                                                        type="radio"
                                                        value={CONSTANTES_ENVIO_EXAME_CLASSIFICAR_DOR_TORACICA.NAO}
                                                        color="primary"
                                                        checked={field.value === CONSTANTES_ENVIO_EXAME_CLASSIFICAR_DOR_TORACICA.NAO}
                                                    />
                                                }
                                                label={strings.nao}
                                            />
                                            <SttFormControlLabel
                                                {...field}
                                                control={
                                                    <SttRadioButton
                                                        type="radio"
                                                        value={CONSTANTES_ENVIO_EXAME_CLASSIFICAR_DOR_TORACICA.SIM}
                                                        color="primary"
                                                        checked={field.value === CONSTANTES_ENVIO_EXAME_CLASSIFICAR_DOR_TORACICA.SIM}
                                                    />
                                                }
                                                label={strings.sim}
                                            />
                                            {
                                                meta.touched && meta.error &&
                                                <SttFormHelperText error>
                                                    {meta.error}
                                                </SttFormHelperText>
                                            }
                                        </SttRadioGroup>
                                    </SttFormControl>
                                </div>
                            )}
                        </FastField>
                    </SttGrid>

                }

                {
                    values[CLASSIFICAR_DOR_TORACICA] === CONSTANTES_ENVIO_EXAME_CLASSIFICAR_DOR_TORACICA.SIM &&
                    <>
                        <SttGrid item xs={12} sm={6} md={4}>
                            <Field name={INTENSIDADE_DOR}>
                                {({
                                    field: { name, value, onBlur },
                                    meta,
                                }) => (
                                    <SttAutocomplete
                                        inputprops={{
                                            name: name,
                                            label: strings.intensidadeDor,
                                            required: true,
                                            error: meta.touched && meta.error ? meta.error : undefined
                                        }}
                                        getOptionLabel={option => option && option.descricao || ''}
                                        getOptionSelected={(option, val) => option && val && option.id === val.id}
                                        options={CONSTANTES_INTENSIDADE_DOR}
                                        value={value}
                                        onBlur={onBlur}
                                        onChange={(e, item) => {
                                            setFieldValue(INTENSIDADE_DOR, item || null);
                                        }}
                                    />
                                )
                                }
                            </Field>
                        </SttGrid>

                        <SttGrid item xs={12}>
                            <SttHeading required variant="h5" color="primary">{strings.localizacaoIrradiacaoDor}</SttHeading>
                            <SttFormControl variant="outlined" fullWidth>
                                <SttFormGroup>
                                    <FieldArray
                                        name={LOCALIZACAO_DOR}
                                        render={({ push, remove }) => (
                                            <SttGrid container>
                                                {
                                                    localizacoesDor.map((localizacao) => {
                                                        return (
                                                            <SttGrid item xs={12} md={6} lg={4}>
                                                                <SttFormControlLabel
                                                                    key={uuidv4()}
                                                                    control={
                                                                        <SttCheckbox
                                                                            value={localizacao}
                                                                            color="primary"
                                                                            checked={!!values[LOCALIZACAO_DOR].find(el => el.id === localizacao.id)}
                                                                            onChange={event => {
                                                                                if (event.target.checked) {
                                                                                    push(localizacao);
                                                                                } else {
                                                                                    let indice = values[LOCALIZACAO_DOR].findIndex(el => el.id === localizacao.id);
                                                                                    remove(indice);
                                                                                }

                                                                            }}
                                                                        />
                                                                    }
                                                                    label={localizacao.descricao}
                                                                />
                                                            </SttGrid>
                                                        )
                                                    })
                                                }
                                            </SttGrid>
                                        )}
                                    />
                                    {
                                        touched && touched[LOCALIZACAO_DOR] &&
                                        errors && errors[LOCALIZACAO_DOR] &&
                                        values[LOCALIZACAO_DOR].length === 0 &&
                                        <SttFormHelperText error>
                                            {errors[LOCALIZACAO_DOR]}
                                        </SttFormHelperText>
                                    }
                                </SttFormGroup>
                            </SttFormControl>
                        </SttGrid>

                        <SttGrid item xs={12}>
                            <SttHeading required variant="h5" color="primary">{strings.tipoDor}</SttHeading>
                            <SttFormControl variant="outlined" fullWidth>
                                <SttFormGroup>
                                    <FieldArray
                                        name={TIPO_DOR}
                                        render={({ push, remove }) => (
                                            <SttGrid container>
                                                {
                                                    tiposDor.map((tipoDor) => {
                                                        return (
                                                            <SttGrid item xs={12} md={6} lg={4}>
                                                                <SttFormControlLabel
                                                                    control={
                                                                        <SttCheckbox
                                                                            value={tipoDor}
                                                                            color="primary"
                                                                            checked={verificarCheckedTipoDor(tipoDor)}
                                                                            onChange={event => {
                                                                                if (event.target.checked) {
                                                                                    push(tipoDor);
                                                                                } else {
                                                                                    let indice = values[TIPO_DOR].findIndex(el => el.id === tipoDor.id);
                                                                                    remove(indice);
                                                                                }

                                                                            }}
                                                                        />
                                                                    }
                                                                    label={tipoDor.descricao}
                                                                />
                                                            </SttGrid>
                                                        )
                                                    })
                                                }
                                            </SttGrid>
                                        )}
                                    />
                                    {
                                        touched && touched[TIPO_DOR] &&
                                        errors && errors[TIPO_DOR] &&
                                        values[TIPO_DOR].length === 0 &&
                                        <SttFormHelperText error>
                                            {errors[TIPO_DOR]}
                                        </SttFormHelperText>
                                    }
                                </SttFormGroup>
                            </SttFormControl>
                        </SttGrid>

                        {
                            values[TIPO_DOR].find(tipoDor => tipoDor.necessita_complemento) &&
                            <SttGrid item xs={12}>
                                <FastField name={OUTRO_TIPO_DOR}>
                                    {({
                                        field,
                                        meta
                                    }) => (
                                        <SttInput
                                            inputProps={{
                                                maxLength: 255
                                            }}
                                            {...field}
                                            label={strings.outroTipoDor}
                                            error={meta.touched && meta.error ? true : false}
                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                        />
                                    )}
                                </FastField>
                            </SttGrid>
                        }

                        <SttGrid item xs={12}>
                            <SttHeading required variant="h5" color="primary">{strings.caracterizacaoDorToracica}</SttHeading>
                            <SttFormControl variant="outlined" fullWidth>
                                <SttFormGroup>
                                    <FieldArray
                                        name={CARACTERIZACAO_DOR_TORACICA}
                                        render={({ push, remove }) => (
                                            <SttGrid container>
                                                {
                                                    caracterizacaoDorToracica.map((caracterizacaoDorToracica) => {
                                                        return (
                                                            <SttGrid item xs={12} md={6}>
                                                                <SttFormControlLabel
                                                                    control={
                                                                        <SttCheckbox
                                                                            value={caracterizacaoDorToracica}
                                                                            color="primary"
                                                                            checked={verificarCheckedCaracterizacaoDorToracica(caracterizacaoDorToracica)}
                                                                            onChange={event => {
                                                                                if (event.target.checked) {
                                                                                    push(caracterizacaoDorToracica);
                                                                                    onChangeCaracterizacaoDorToracica(caracterizacaoDorToracica, remove);
                                                                                } else {
                                                                                    let indice = values[CARACTERIZACAO_DOR_TORACICA].findIndex(el => el.id === caracterizacaoDorToracica.id);
                                                                                    remove(indice);
                                                                                }

                                                                            }}
                                                                        />
                                                                    }
                                                                    label={caracterizacaoDorToracica.descricao}
                                                                />
                                                            </SttGrid>
                                                        )
                                                    })
                                                }
                                            </SttGrid>
                                        )}
                                    />
                                    {
                                        touched && touched[CARACTERIZACAO_DOR_TORACICA] &&
                                        errors && errors[CARACTERIZACAO_DOR_TORACICA] &&
                                        values[CARACTERIZACAO_DOR_TORACICA].length === 0 &&
                                        <SttFormHelperText error>
                                            {errors[CARACTERIZACAO_DOR_TORACICA]}
                                        </SttFormHelperText>
                                    }
                                </SttFormGroup>
                            </SttFormControl>
                        </SttGrid>
                    </>
                }
            </SttGrid>
        </Fragment>
    );
}

export default DadosExame;