import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Field, Formik, useFormikContext } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../request';
import { NotificationManager } from 'react-notifications';
import {
    SttTable,
    SttTableHead,
    SttTableRow,
    SttTableCell,
    SttTableBody,
    SttTranslateHook,
    SttInput,
    SttHeading,
    SttModal,
    SttHidden,
    SttButton,
    SttGrid,
    SttFormControlLabel,
    SttRadioButton,
    SttFormControl,
    SttRadioGroup,
    SttAutocomplete,
    SttNotification,
    SttAlertTitle,
    SttFormHelperText,
    SttCheckbox,
    SttText
} from '@stt-componentes/core';
import {
    SttAjudaIcon,
} from '@stt-componentes/icons';
import AddIcon from '@material-ui/icons/Add';
import { DESCRITORES_NORMAIS, PRIORIDADE_GRAVE } from '../../common/Constants';
import { ATRIBUTO, DESCRITORES_SBC, MODIFICADOR, OBSERVACAO, LAUDO_GRAVE } from './fieldNames';
import { setParametrosAlerta as setParametrosAlertaAction, setOpen as setOpenAction } from '../../redux/actions/alerta';
import { connect } from 'react-redux';
import { TableContainer } from '@material-ui/core';
import 'react-notifications/lib/notifications.css';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
    table: {
        maxHeight: '500px',
        overflow: 'auto',
    },
    tableHead: {
        padding: '10px'
    },
    tableCell: {
        padding: '0px 0px 0px 10px',
        fontSize: '12px'
    },
    chip: {
        margin: theme.spacing(0.5)
    },
    notification: {
        marginBottom: theme.spacing(2)
    }
}));

const LaudoTextual = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const {
        setParametrosAlerta,
        setOpen
    } = props;

    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;
    const classes = useStyles();
    const refModalDescritor = useRef();
    const { values, setFieldValue, field } = useFormikContext();

    const [descritoresSbc, setDescritoresSbc] = useState(null);
    const [modificadoresSbc, setModificadoresSbc] = useState(null);
    const [atributosPosDescritorSbc, setAtributosPosDescritorSbc] = useState(null);
    const [filtro, setFiltro] = useState('');
    const [modalAberto, setModalAberto] = useState(false);

    // Atributos pós-descritor
    const [estadoInicialAtributoPosDescritor, setEstadoInicialAtributoPosDescritor] = useState(null);

    // Serve como um auxiliar para armazenar o descritor escolhido enquanto o usuário informa os atributos pós-descritores
    const [descritorAtributoPosDescritor, setDescritorAtributoPosDescritor] = useState(null);

    useEffect(() => {
        // Baixar os descritores da SBC para laudo
        axios
            .get(`${ELETRO_API_BASE_URL}/pesquisar-descritores-sbc`, { headers: getHeaders() })
            .then((response) => {
                if (response?.data?.data) {
                    setDescritoresSbc(response.data.data);
                    setDescritorPadrao(response.data.data);
                }
            })
            .catch(err => {
                console.log(err);
            });

        // Baixar os modificadores
        axios
            .get(`${ELETRO_API_BASE_URL}/pesquisar-modificadores-sbc`, { params: { ativo: true }, headers: getHeaders() })
            .then((response) => {
                if (response?.data?.data) {
                    let modificadores = response.data.data;
                    setModificadoresSbc(modificadores);
                    setEstadoInicialAtributoPosDescritor(
                        {
                            modificador: modificadores.find(el => el.descricao === 'Nenhum').id,
                            atributo: null,
                            observacao: ''
                        }
                    )
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    /**
     * Caso não haja nenhum descritor inserido, insere como default o descritor "Normal"
     * @param {*} descritores 
     */
    const setDescritorPadrao = (descritores) => {
        const descritorNormal = descritores.filter((desc) => desc.sigla === DESCRITORES_NORMAIS.NORMAL);
        if (!values[DESCRITORES_SBC].length) {
            setFieldValue(DESCRITORES_SBC, descritorNormal);
        }
    };

    /**
     * Função que verifica o descritor e adiciona um novo descritor ao array,
     * ou reserva o descritor para informar as informações adicionais sobre o mesmo
     * @param {*} descritor 
     * @returns 
     */
    const adicionarNovoDescritor = (descritor) => {
        if(verificarDescritorRepetido(descritor)) {
            return;
        }

        if (descritor.sigla === DESCRITORES_NORMAIS.NORMAL) {
            setFiltro('');
            setFieldValue(DESCRITORES_SBC, [descritor])
            return;
        }

        // Caso o descritor não esteja duplicado ou não for considerado sem atributo pós-descritor, abrir o modal para escolha
        setDescritorAtributoPosDescritor(descritor);

        // Baixar os atributos pós-descritor
        axios
            .get(`${ELETRO_API_BASE_URL}/pesquisar-atributos-sbc`, { params: { ativo: true, siglaSBC: descritor.sigla }, headers: getHeaders() })
            .then((response) => {
                if (response?.data?.data) {
                    setAtributosPosDescritorSbc(response.data.data);
                    setModalAberto(true);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    // Função que filtra os descritores a partir do filtro criado
    const filtrarDescritores = () => {
        if (filtro && filtro.length > 0) {
            return descritoresSbc.filter((descritor) =>
                descritor.sigla.toLowerCase().includes(filtro.toLowerCase()) ||
                descritor.descritor.toLowerCase().includes(filtro.toLowerCase())
            );
        }

        return descritoresSbc;
    };

    const verificarCorFundoDescritor = (descritor) => {
        return descritor.sigla === DESCRITORES_NORMAIS.NORMAL ?
            '#ADFF2F' : '';
    };

    const mostrarInformacaoAdicional = (descritor) => {
        let mensagem = '';
        if (descritor.definicao_sbc) {
            mensagem += descritor.definicao_sbc;
        }

        if (descritor.informacoes_complementares) {
            if (mensagem.length) {
                mensagem += `\n\n`;
            }
            mensagem += descritor.informacoes_complementares;
        }

        const parametrosAlerta = {
            titulo: `${descritor.sigla} - ${descritor.descritor}`,
            mensagem: mensagem,
            tipo: 'info',
            open: true,
            opcoes: [
                {
                    title: strings.ok,
                    onClick: () => {
                        setOpen(false);
                    }
                }
            ]
        };
        setParametrosAlerta(parametrosAlerta);
    };

    const callbackFecharModalAdicaoModificador = (laudoGrave) => {
        //if (laudoGrave) {
        if (false) {
            NotificationManager.error(strings.descritorGraveAdicionado);
            setFieldValue(LAUDO_GRAVE, true);
        }
        setFiltro('');
        setDescritorAtributoPosDescritor(null);
        setModalAberto(false);
    };

    const cancelarModalAdicaoModificador = () => {
        setDescritorAtributoPosDescritor(null);
        setModalAberto(false);
    };

    const confirmarNovoTermo = () => {
        if (refModalDescritor && refModalDescritor.current) {
            refModalDescritor.current.handleSubmit();
        }
    };

    const deletarDescritor = (indice) => {
        const descritoresAtualizados = [...values[DESCRITORES_SBC]];
        descritoresAtualizados.splice(indice, 1);
        setFieldValue(DESCRITORES_SBC, descritoresAtualizados);
    };

    const verificarDescritorRepetido = (descritor) => {
        if (values[DESCRITORES_SBC].some(obj => JSON.stringify(obj.sigla) === JSON.stringify(descritor.sigla))) {
            const parametrosAlerta = {
                titulo: strings.atencao,
                mensagem: strings.descritorDuplicado,
                tipo: 'alert',
                open: true,
                opcoes: [
                    {
                        title: strings.ok,
                        onClick: () => {
                            setOpen(false);
                        }
                    }
                ]
            };
            setParametrosAlerta(parametrosAlerta);
            return true;
        }
    }

    return (
        descritoresSbc &&
        <div>

            <SttFormControl variant="outlined">
                <SttFormControlLabel
                    control={
                        <SttCheckbox
                            {...field}
                            value={values[LAUDO_GRAVE]}
                            color="primary"
                            checked={values[LAUDO_GRAVE]}
                            onChange={event => {
                                setFieldValue(LAUDO_GRAVE, !!event.target.checked);
                            }}
                        />
                    }
                    label={strings.laudoImportante}
                />
            </SttFormControl>

            <SttNotification severity="info" className={classes.notification}>
                <SttAlertTitle>{strings.descritoresAdicionados}</SttAlertTitle>
                {
                    values[DESCRITORES_SBC]?.length ?
                        <>
                            {
                                values[DESCRITORES_SBC].map((descritor, indice) => {
                                    return <Chip
                                        className={classes.chip}
                                        label={descritor.descritor}
                                        onDelete={() => deletarDescritor(indice)}
                                        color="primary"
                                        variant="outlined"
                                    />
                                })
                            }
                        </> :
                        <>
                            {strings.nenhumDescritorAdicionado}!
                        </>
                }
            </SttNotification>

            <SttHeading variant="h5" color="primary">{strings.textoDescritorSBC}</SttHeading>
            <SttInput
                label={strings.descritorSBC}
                value={filtro}
                onChange={(event) => setFiltro(event.target.value)}
                onKeyDown={(e) => {
                    let descritores = filtrarDescritores();
                    if (e.key === 'Enter' && descritores.length === 1) {
                        e.preventDefault();
                        adicionarNovoDescritor(descritores[0]);
                    }
                }}
            />

            <SttHidden smDown>
                <TableContainer className={classes.table}>
                    <SttTable size="small">
                        <SttTableHead>
                            <SttTableRow>
                                <SttTableCell className={classes.tableHead} width="25%">
                                    {strings.sigla}
                                </SttTableCell>
                                <SttTableCell className={classes.tableHead} width="60%">
                                    {strings.nome}
                                </SttTableCell>
                                <SttTableCell className={classes.tableHead} width="10%">
                                    {strings.informacao}
                                </SttTableCell>
                                <SttTableCell className={classes.tableHead} width="5%">
                                    {strings.adicionar}
                                </SttTableCell>
                            </SttTableRow>
                        </SttTableHead>
                        <SttTableBody>
                            {
                                filtrarDescritores().map((row, index) => (
                                    <SttTableRow key={index} style={{ backgroundColor: verificarCorFundoDescritor(row), cursor: 'pointer' }}
                                        onDoubleClick={() => {
                                            if(verificarDescritorRepetido(row)) {
                                                return;
                                            }
                                            
                                            const descritoresSbcAtualizados = values[DESCRITORES_SBC];
                                            descritoresSbcAtualizados.push(row);
                                            const indexDescritorNormal = descritoresSbcAtualizados.findIndex((desc) => desc.sigla === DESCRITORES_NORMAIS.NORMAL);
                                            if (indexDescritorNormal >= 0) {
                                                setFieldValue(DESCRITORES_SBC, [row]);
                                                return;
                                            }

                                            setFieldValue(DESCRITORES_SBC, descritoresSbcAtualizados);
                                        }}
                                    >
                                        <SttTableCell className={classes.tableCell}>{row.sigla}</SttTableCell>
                                        <SttTableCell className={classes.tableCell}>{row.descritor}</SttTableCell>
                                        <SttTableCell className={classes.tableCell} align="center"><SttAjudaIcon style={{ cursor: 'pointer', width: '60%' }} onClick={() => mostrarInformacaoAdicional(row)} /></SttTableCell>
                                        <SttTableCell className={classes.tableCell} align="center"><AddIcon style={{ color: '#0A6FB8', cursor: 'pointer' }} onClick={() => adicionarNovoDescritor(row)} /></SttTableCell>
                                    </SttTableRow>
                                ))
                            }
                        </SttTableBody>
                    </SttTable>
                </TableContainer>
            </SttHidden>
            <SttHidden mdUp>
                <TableContainer className={classes.table}>
                    <SttTable>
                        {
                            filtrarDescritores().map((row, index) => (

                                <SttTableRow key={index} style={{ backgroundColor: verificarCorFundoDescritor(row) }}>
                                    <SttTableCell width="70%">
                                        <SttText size="small">
                                            <b>{strings.sigla}:</b> {row.sigla}
                                        </SttText>
                                        <SttText size="small">
                                            <b>{strings.nome}:</b> {row.descritor}
                                        </SttText>
                                    </SttTableCell>
                                    <SttTableCell width="30%">
                                        <SttAjudaIcon
                                            style={{ cursor: 'pointer', width: '60%' }}
                                            onClick={() => mostrarInformacaoAdicional(row)}
                                        />
                                        <AddIcon
                                            style={{ color: '#0A6FB8', cursor: 'pointer' }}
                                            onClick={() => adicionarNovoDescritor(row)}
                                        />
                                    </SttTableCell>
                                </SttTableRow>

                            ))
                        }
                    </SttTable>
                </TableContainer>
            </SttHidden>

            <SttModal
                title={strings.adicionandoTermoSBC}
                open={modalAberto}
                outModalClose={cancelarModalAdicaoModificador}
                iconClose={cancelarModalAdicaoModificador}
                maxWidth="md"
                fullWidth={true}
                children={
                    <div>
                        <div className={classes.form}>

                            <Formik
                                innerRef={refModalDescritor}
                                initialValues={estadoInicialAtributoPosDescritor}
                                onSubmit={(data, { setFieldValue }) => {
                                    descritorAtributoPosDescritor.modificador = modificadoresSbc.find(el => el.id == data.modificador);

                                    descritorAtributoPosDescritor.atributo = data.atributo;

                                    if (data.observacao) {
                                        descritorAtributoPosDescritor.observacao = data.observacao.trim();
                                    }
                                    const descritoresSbcAtualizados = values[DESCRITORES_SBC];
                                    const indexDescritorNormal = descritoresSbcAtualizados.findIndex((desc) => desc.sigla === DESCRITORES_NORMAIS.NORMAL);
                                    if (indexDescritorNormal >= 0) {
                                        descritoresSbcAtualizados.splice(indexDescritorNormal, 1);
                                    }
                                    descritoresSbcAtualizados.push(descritorAtributoPosDescritor);
                                    setFieldValue(DESCRITORES_SBC, descritoresSbcAtualizados);
                                    callbackFecharModalAdicaoModificador(descritorAtributoPosDescritor.prioridade === PRIORIDADE_GRAVE);
                                }}
                            >
                                {
                                    ({
                                        handleSubmit,
                                        values
                                    }) => {
                                        return (
                                            <>
                                                <form onSubmit={handleSubmit} noValidate onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                        handleSubmit();
                                                    }
                                                }}>
                                                    <SttGrid container spacing={3}>
                                                        {
                                                            <>
                                                                <SttGrid item xs={12}>
                                                                    <Field name={`${MODIFICADOR}`}>
                                                                        {({
                                                                            field,
                                                                            meta,
                                                                        }) => (
                                                                            <div>
                                                                                <SttFormControl required error={meta.touched && meta.error ? true : false} variant="outlined">
                                                                                    <SttRadioGroup
                                                                                        row
                                                                                        onChange={() => { }}
                                                                                    >
                                                                                        {
                                                                                            modificadoresSbc.map((modificador) => {
                                                                                                return <SttFormControlLabel
                                                                                                    {...field}
                                                                                                    control={
                                                                                                        <SttRadioButton
                                                                                                            type="radio"
                                                                                                            value={modificador.id}
                                                                                                            color="primary"
                                                                                                            checked={values[MODIFICADOR] == modificador.id}
                                                                                                        />
                                                                                                    }
                                                                                                    label={modificador.descricao}
                                                                                                />
                                                                                            })
                                                                                        }

                                                                                        {
                                                                                            meta.touched && meta.error &&
                                                                                            <SttFormHelperText error>
                                                                                                {meta.error}
                                                                                            </SttFormHelperText>
                                                                                        }
                                                                                    </SttRadioGroup>
                                                                                </SttFormControl>
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </SttGrid>
                                                                <SttGrid item xs={12}>
                                                                    <Field name={ATRIBUTO}>
                                                                        {({
                                                                            field: { name, value, onBlur },
                                                                            form: { values, setFieldValue }
                                                                        }) => (
                                                                            <SttAutocomplete
                                                                                inputprops={{
                                                                                    name: name,
                                                                                    label: strings.atributoPosDescritor
                                                                                }}
                                                                                getOptionLabel={option => option && option.descricao || ''}
                                                                                getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                                                options={atributosPosDescritorSbc}
                                                                                value={value}
                                                                                onBlur={onBlur}
                                                                                onChange={(e, item) => {
                                                                                    const atributo = item || null;
                                                                                    setFieldValue(ATRIBUTO, atributo);
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </SttGrid>
                                                                <SttGrid item xs={12}>
                                                                    <Field name={OBSERVACAO}>
                                                                        {({
                                                                            field
                                                                        }) => (
                                                                            <SttInput
                                                                                {...field}
                                                                                multiline={true}
                                                                                rows={5}
                                                                                label={strings.observacao}
                                                                                inputProps={{
                                                                                    maxLength: 200
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </SttGrid>
                                                            </>
                                                        }
                                                    </SttGrid>
                                                </form>
                                            </>
                                        )
                                    }
                                }
                            </Formik>
                        </div>
                    </div>
                }
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" onClick={confirmarNovoTermo}>
                            {strings.confirmar}
                        </SttButton>
                        <SttButton variant="outlined" color="primary" onClick={cancelarModalAdicaoModificador}>
                            {strings.cancelar}
                        </SttButton>
                    </div>
                }
            />
        </div>
    );
}

LaudoTextual.propTypes = {
    strings: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosAlerta: parametros => dispatch(setParametrosAlertaAction(parametros)),
        setOpen: open => dispatch(setOpenAction(open))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LaudoTextual);