import * as yup from 'yup';
import { CARACTERIZACAO_DOR_TORACICA, CLASSIFICAR_DOR_TORACICA, DOR_MOMENTO_EXAME, INTENSIDADE_DOR, LOCALIZACAO_DOR, OUTRO_TIPO_DOR, TIPO_DOR } from './fieldNames';

const ValidationDadosExame = (strings, user) => {
    return yup.object().shape({
        [DOR_MOMENTO_EXAME]: yup.string().matches(/(S|N)/).required(strings.campoObrigatorio),
        [CLASSIFICAR_DOR_TORACICA]: yup.string()
            .when(`${DOR_MOMENTO_EXAME}`, {
                is: (val) => val === 'S',
                then: yup.string().matches(/(S|N)/).required(strings.campoObrigatorio)
            }),
        [TIPO_DOR]: yup.array()
            .when(`${CLASSIFICAR_DOR_TORACICA}`, {
                is: (val) => val === 'S',
                then: yup.array().of(
                    yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string(),
                        necessita_complemento: yup.boolean()
                    }).required()
                ).min(1, strings.tipoDorObrigatorio),
            }),
        [OUTRO_TIPO_DOR]: yup.string()
            .nullable()
            .when(`${TIPO_DOR}`, {
                is: (val) => val?.find(v => v.necessita_complemento),
                then: yup.string().nullable().required(strings.campoObrigatorio)
            }),
        [LOCALIZACAO_DOR]: yup.array()
            .when(`${CLASSIFICAR_DOR_TORACICA}`, {
                is: (val) => val === 'S',
                then: yup.array().of(
                    yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string()
                    }).required()
                ).min(1, strings.localizacaoIrradiacaoDorObrigatoria)
            }),
        [CARACTERIZACAO_DOR_TORACICA]: yup.array()
            .when(`${CLASSIFICAR_DOR_TORACICA}`, {
                is: (val) => val === 'S',
                then: yup.array().of(
                    yup.object().shape({
                        id: yup.number(),
                        descricao: yup.string(),
                        nenhuma_informacao: yup.boolean()
                    }).required()
                ).min(1, strings.caracterizacaoDorToracicaObrigatoria),
            }),
        [INTENSIDADE_DOR]: yup.object()
            .nullable()
            .when(`${CLASSIFICAR_DOR_TORACICA}`, {
                is: (val) => val === 'S',
                then: yup.object().shape({
                    id: yup.number(),
                    descricao: yup.string()
                }).nullable().required(strings.campoObrigatorio),
            }),
    });
}

export { ValidationDadosExame };