import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import { setParametrosPesquisa as setParametrosPesquisaAction, limparParametrosPesquisa as limparParametrosPesquisaAction } from '../../../redux/actions/envioImagens';
import { Formik, Field, FastField } from 'formik';
import axios from 'axios';
import { getHeaders } from '../../../request';
import { useMoment } from '../../../hooks';
import validationSchema from './validationSchema';
import { temPerfilRBAC } from '../../../security/acl';
import PropTypes from 'prop-types';
import { estadoInicialForm } from './estadoInicialForm';
import { PERFIL } from '../../../common/Constants';
import {
    SttGrid,
    SttDatePicker,
    SttInput,
    SttAutocomplete,
    SttButton,
    SttMaskedInput
} from '@stt-componentes/core';
import { 
    DATA_DE,
    DATA_ATE,
    NOME_SOLICITANTE,
    NOME_PACIENTE,
    CPF_PACIENTE,
    CNS_PACIENTE,
    UF,
    MUNICIPIO
} from './fieldNames';

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    }
}));

const Form = (props) => {
    const { 
        strings, 
        user, 
        callbackBusca, 
        callbackAndamento, 
        page, 
        count, 
        orderBy, 
        order, 
        buscar, 
        resetPageOrder,
        parametrosPesquisa,
        setParametrosPesquisa,
        limparParametrosPesquisa
    } = props;
    const schema = validationSchema(strings);
    const moment = useMoment();
    const [carregarUfs, setCarregarUfs] = useState(false);
    const [ufs, setUfs] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const formRef = useRef()
    const classes = useStyles();
    const UTILITARIOS_API_BASE_URL = global.gConfig.url_base_utilitarios;
    const ELETRO_API_BASE_URL = global.gConfig.url_base_eletro;

    useEffect(() => {
        if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) {
            setCarregarUfs(true);
        }            
    }, []);

    useEffect(() => {
        if (carregarUfs) {
            let url = `${UTILITARIOS_API_BASE_URL}/localizacao/estado`;
            if (temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) {
                const perfil = user.perfisRBAC.filter(p => p.identificador === PERFIL.ADMINISTRADOR_ESTADUAL);
                const estados = perfil[0].estados;
                const idsUf = estados.map(e => e.id);
                url = `${url}?ids=${idsUf.join(',')}`;
            }
            axios
                .get(url)
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        setUfs(itens);
                    }
                })
                .catch(err => console.log(err));
        }
    }, [carregarUfs]);

    useEffect(() => {
        if (buscar && formRef.current) {
            formRef.current.handleSubmit();
        }
    }, [buscar]);

    const handleChangeUf = (uf) => {
        if (uf) {
            axios
                .get(`${UTILITARIOS_API_BASE_URL}/localizacao/cidade?id-estado=${uf.id}`)
                .then((response) => {
                    if (response.data) {
                        const { itens } = response.data.data;
                        setMunicipios(itens);
                    } else {
                        setMunicipios([]);
                    }
                })
                .catch(err => console.log(err));
        } else {
            setMunicipios([]);
        }
    };

    return (
        <Formik
            innerRef={formRef}
            initialValues={parametrosPesquisa}
            validationSchema={schema}
            onSubmit={(data, { setSubmitting }) => {
                setParametrosPesquisa(data);

                setSubmitting(true);
                callbackAndamento(true);

                let params = {};
                if (data.de) {
                    params.de = moment(data.de).format('YYYY-MM-DD');
                }
                if (data.ate) {
                    params.ate = moment(data.ate).format('YYYY-MM-DD');
                }
                if (data.solicitante) {
                    params.solicitante = data.solicitante.trim();   
                }
                if (data.paciente) {
                    params.paciente = data.paciente.trim();
                }
                if (data.cpf) {
                    params.cpf = data.cpf;
                }
                if (data.cns) {
                    params.cns = data.cns;
                }
                if (data.uf) {
                    params.uf = data.uf.id;
                }
                if (data.municipio) {
                    params.municipio = data.municipio.id;
                }
                if (orderBy && order) {
                    params.sort = orderBy;
                    params.direction = order;
                }

                const offset = (page * count);
                params.start = offset;
                params.count = count;

                axios
                    .get(`${ELETRO_API_BASE_URL}/solicitacao`, { params, headers: getHeaders() })
                    .then((response) => {
                        const dados = response.data.data;
                        callbackBusca(dados);
                    })
                    .catch(err => {
                        console.error(err);
                        callbackBusca(null);
                    })
                    .finally(() => {
                        setSubmitting(false);
                        callbackAndamento(false);
                    });
            }}
        >
            {
                ({
                    isSubmitting,
                    handleSubmit,
                    resetForm
                }) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate>
                            <SttGrid container spacing={2}>
                                <SttGrid item xs={12} sm={12} md={6} lg={4} xl={3}>
                                    <SttGrid container spacing={2}>
                                        <SttGrid item xs={12} sm={6}>
                                            <FastField name={DATA_DE}>
                                                {({
                                                    field: { name, value, },
                                                    form: { setFieldValue, setFieldError, setFieldTouched },
                                                    meta
                                                }) => {
                                                    return (
                                                        <SttDatePicker
                                                            label={strings.dataInicialSolicitacao} 
                                                            inputprops={{
                                                                name: name
                                                            }}
                                                            disableFuture
                                                            maxDate={new Date()}
                                                            error={meta.touched && meta.error ? true : false}
                                                            onError={error => {
                                                                setFieldError(DATA_DE, meta.error || error);
                                                            }}
                                                            value={value}
                                                            onBlur={() => {
                                                                setFieldTouched(DATA_DE, true);
                                                            }}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            onChange={date => setFieldValue(DATA_DE, date, true)}
                                                            onClose={() => setFieldTouched(DATA_DE, true)}
                                                        />
                                                    );
                                                }}
                                            </FastField>
                                        </SttGrid>
                                        <SttGrid item xs={12} sm={6}>
                                            <FastField name={DATA_ATE}>
                                                {({
                                                    field: { name, value, },
                                                    form: { setFieldValue, setFieldError, setFieldTouched },
                                                    meta
                                                }) => {
                                                    return (
                                                        <SttDatePicker
                                                            label={strings.dataFinalSolicitacao} 
                                                            inputprops={{
                                                                name: name
                                                            }}
                                                            disableFuture
                                                            maxDate={new Date()}
                                                            error={meta.touched && meta.error ? true : false}
                                                            onError={error => {
                                                                setFieldError(DATA_ATE, meta.error || error);
                                                            }}
                                                            value={value}
                                                            onBlur={() => {
                                                                setFieldTouched(DATA_ATE, true);
                                                            }}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                            onChange={date => setFieldValue(DATA_ATE, date, true)}
                                                            onClose={() => setFieldTouched(DATA_ATE, true)}
                                                        />
                                                    );
                                                }}
                                            </FastField>
                                        </SttGrid>
                                    </SttGrid>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                    <FastField name={NOME_SOLICITANTE}>
                                        {({
                                            field
                                        }) => (
                                            <SttInput 
                                                {...field}
                                                label={strings.medicoSolicitante}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                    <FastField name={NOME_PACIENTE}>
                                        {({
                                            field
                                        }) => (
                                            <SttInput 
                                                {...field}
                                                label={strings.nomePaciente}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                    <FastField name={CPF_PACIENTE}>
                                        {({
                                            field,
                                            meta
                                        }) => (
                                            <SttMaskedInput
                                                {...field}
                                                mask="cpf"
                                                label={strings.cpfPaciente}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                <SttGrid item xs={12} sm={6} md={3} lg={3} xl={2}>
                                    <FastField name={CNS_PACIENTE}>
                                        {({
                                            field,
                                            meta,
                                        }) => (
                                            <SttMaskedInput
                                                {...field}
                                                mask="cns"
                                                label={strings.cnsPaciente}
                                                error={meta.touched && meta.error ? true : false}
                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                            />
                                        )}
                                    </FastField>
                                </SttGrid>
                                {
                                    (temPerfilRBAC(user, PERFIL.ADMINISTRADOR) || temPerfilRBAC(user, PERFIL.ADMINISTRADOR_ESTADUAL)) &&
                                    <>
                                        <SttGrid item xs={12} sm={3} md={2} lg={1}>
                                            <Field name={UF}>
                                                {({
                                                    field: { name, value, onBlur },
                                                    form: { setFieldValue }
                                                }) => (
                                                    <SttAutocomplete 
                                                        inputprops={{
                                                            name: name,
                                                            label: strings.uf
                                                        }}
                                                        getOptionLabel={option => option && option.sigla || ''}
                                                        getOptionSelected={(option, val) => option && val && option.id === val.id}
                                                        options={ufs}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        onChange={(e, item) => {
                                                            const uf = item || null;
                                                            setFieldValue(UF, uf);
                                                            setFieldValue(MUNICIPIO, null);
                                                            handleChangeUf(uf)
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>
                                        <SttGrid item xs={12} sm={9} md={4} lg={4} xl={3}>
                                            <Field name={MUNICIPIO}>
                                                {({
                                                    field: { name, value, onBlur },
                                                    form: { setFieldValue }
                                                }) => (
                                                    <SttAutocomplete 
                                                        inputprops={{
                                                            name: name,
                                                            label: strings.municipio
                                                        }}
                                                        getOptionLabel={option => option && option.nome || ''}
                                                        getOptionSelected={(option, v) => option && v && option.id === v.id}
                                                        options={municipios}
                                                        value={value}
                                                        onBlur={onBlur}
                                                        onChange={(e, item) => {
                                                            setFieldValue(MUNICIPIO, item || null);
                                                        }}
                                                    />
                                                )}
                                            </Field>
                                        </SttGrid>
                                    </>
                                }
                            </SttGrid>
                            <SttGrid container spacing={3}>
                                <SttGrid item xs={12} className={classes.buttonWrapper}>
                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => {
                                            resetPageOrder();
                                        }}
                                    >
                                        {strings.pesquisar}
                                    </SttButton>
                                    <SttButton
                                        type="button"
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitting}
                                        onClick={() => {
                                            limparParametrosPesquisa();
                                            resetForm({
                                                values: estadoInicialForm
                                            });
                                            callbackBusca(null);
                                        }}
                                    >
                                        {strings.limpar}
                                    </SttButton>
                                </SttGrid>
                            </SttGrid>
                        </form>
                    )
                }
            }
        </Formik>
    );
};

Form.propTypes = {
    strings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    callbackBusca: PropTypes.func.isRequired,
    callbackAndamento: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired, 
    count: PropTypes.number.isRequired, 
    orderBy: PropTypes.string, 
    order: PropTypes.string.isRequired, 
    buscar: PropTypes.bool.isRequired, 
    resetPageOrder: PropTypes.func.isRequired,
    parametrosPesquisa: PropTypes.object.isRequired,
    setParametrosPesquisa: PropTypes.func.isRequired,
    limparParametrosPesquisa: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        parametrosPesquisa: state.envioImagensReducer.parametrosPesquisaSolicitacao
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setParametrosPesquisa: parametros => dispatch(setParametrosPesquisaAction(parametros)),
        limparParametrosPesquisa: () => dispatch(limparParametrosPesquisaAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);