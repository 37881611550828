import React from 'react';
import { connect } from 'react-redux';
import Produtividade from '../../componentes/inicio/produtividade';
import LaudosGraves from '../../componentes/inicio/laudos-graves';
import { temPerfilRBAC } from '../../security/acl';
import { PERFIL } from '../../common/Constants';

const Pendencias = ({user}) => {
    return (
        <>
            {
                (temPerfilRBAC(user, PERFIL.MEDICO_SOLICITANTE) || temPerfilRBAC(user, PERFIL.TECNICO)) &&
                <LaudosGraves />
            }
            <Produtividade />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

export default connect(mapStateToProps, null)(Pendencias);