import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setLaudar as setLaudarAction } from '../../../redux/actions/laudo';
import { SttGrid, SttTabs, SttButton, SttHidden, SttDivider, SttTranslateHook, SttTextItem, SttContainer, SttHeading, SttCard, SttCardHeader, SttCardContent } from '@stt-componentes/core';
import Imagens from '../imagens';
import StickyBox from "react-sticky-box";
import { v4 as uuidv4 } from 'uuid';
import { temPermissaoRBAC } from '../../../security/acl';
import {
    PERMISSOES
} from '../../../common/Constants';
import BotaoInvalidar from './botaoInvalidar';

const useStyles = makeStyles(theme => ({
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    divider: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    }
}));

const ComparacaoExames = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { exameAtual, examesComparacao, user, callbackInvalidar, callbackIgnorarExamesComparacao, ignorarExame } = props;
    const classes = useStyles();

    const [abas, setAbas] = useState([]);

    const emitirLaudo = () => {
        callbackIgnorarExamesComparacao();
    }

    useEffect(() => {
        let dadosAbas = [];
        if (examesComparacao?.length) {
            examesComparacao.forEach((exameComparacao, indice) => {
                dadosAbas.push({
                    id: exameComparacao.exame.id_exame,
                    titulo: `Exame para comparação ${indice + 1}`,
                    conteudo: ExameComparacao,
                    dados: exameComparacao,
                    exameAtual: false
                });
            });
        }
        setAbas(dadosAbas);
    }, [examesComparacao]);

    const Opcoes = () => {
        return (
            <SttGrid container spacing={3}>
                <SttGrid item xs={12} className={classes.buttonWrapper}>
                    <BotaoInvalidar callbackInvalidar={callbackInvalidar} exame={exameAtual} />
                    {
                        temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) &&
                        <SttButton
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={emitirLaudo}
                            nomarginleft="true"
                            style={{ marginLeft: '8px' }}
                        >
                            {strings.emitirLaudo}
                        </SttButton>
                    }
                    {
                        temPermissaoRBAC(user, PERMISSOES.LAUDAR_EXAME) &&
                        <SttButton
                            type="button"
                            variant="outlined"
                            color="primary"
                            onClick={ignorarExame}
                        >
                            {strings.proximoExame}
                        </SttButton>
                    }
                </SttGrid>
            </SttGrid>
        );
    }

    const DadosGerais = ({ dados }) => {
        const {
            exame
        } = dados;
        return (
            <SttContainer>
                <SttHeading variant="h1" color="primary" align="center">{strings.detalhesExame}</SttHeading>
                <SttTextItem key={uuidv4()} title={strings.nome} content={exame.nome_paciente} />
                <SttTextItem key={uuidv4()} title={strings.dataNascimento} content={exame.data_nascimento_paciente_formatada} />
                <SttTextItem key={uuidv4()} title={strings.dataExame} content={exame.data_exame} />
                <SttTextItem key={uuidv4()} title={strings.instituicaoSolicitante} content={exame.nome_instituicao} />
                <SttTextItem key={uuidv4()} title={strings.medicoSolicitante} content={exame.nome_medico_solicitante} />
            </SttContainer>
        );
    }


    const Laudo = (laudo) => {
        return (
            <>
                {
                    laudo?.laudo && temPermissaoRBAC(user, PERMISSOES.VISUALIZAR_LAUDO) &&
                    <div dangerouslySetInnerHTML={{ __html: laudo.laudo }}></div>
                }
            </>
        );
    }

    const ExameComparacao = ({ dados, exameAtual = false }) => {
        return (
            <>
                <DadosGerais dados={dados} />
                <SttDivider className={classes.divider} />
                <Imagens idExame={dados.exame.id_exame} anexos={dados.anexos} />
                <SttDivider className={classes.divider} />
                <Laudo laudo={dados.laudo} />
                {
                    exameAtual &&
                    <Opcoes />
                }
            </>
        );
    }

    const ExameCard = ({ dados }) => {
        return (
            <SttCard className={classes.card} variant="outlined">
                <SttCardContent style={{ margin: '10px' }}>
                    {
                        exameAtual &&
                        <SttTabs abas={[
                            {
                                id: dados.exame.id_exame,
                                titulo: `Exame atual`,
                                conteudo: ExameComparacao,
                                dados: dados,
                                exameAtual: true
                            }
                        ]} abaAtiva={'0'} />
                    }
                </SttCardContent>
            </SttCard>
        );
    }

    const ExamesComparacaoCard = () => {
        return (
            <SttCard className={classes.card} variant="outlined">
                <SttCardContent style={{ margin: '10px' }}>
                    <SttTabs abas={abas} abaAtiva={'0'} />
                </SttCardContent>
            </SttCard>
        );
    }
    return (
        <>
            <SttGrid container spacing={3}>
                <SttHidden mdUp>
                    <SttGrid item xs={12}>
                        <ExamesComparacaoCard />
                    </SttGrid>
                    <SttGrid item xs={12}>
                        <ExameCard dados={exameAtual} />
                    </SttGrid>
                </SttHidden>
                <SttHidden only={['xs', 'sm']}>
                    <SttGrid item xs={6}>
                        <ExamesComparacaoCard />
                    </SttGrid>
                    <SttGrid item xs={6}>
                        <StickyBox>
                            <ExameCard dados={exameAtual} />
                        </StickyBox>
                    </SttGrid>
                </SttHidden>
            </SttGrid>
        </>
    );
};

ComparacaoExames.propTypes = {
    strings: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLaudar: laudar => dispatch(setLaudarAction(laudar))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ComparacaoExames);