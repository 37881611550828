import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { SttTabs } from '@stt-componentes/core';
import Aba from '../../componentes/exame/aba';
import { reset as resetAction } from '../../redux/actions/exame';
import { temPermissaoRede } from '../../security/acl';
import {
    PERMISSOES,
    PERFIL,
    VINCULO_PERFIL,
    TIPO_ABA_EXAME,
    MODALIDADE,
    RELACAO_INSTITUICAO_MODALIDADE_SERVICO
} from '../../common/Constants';

const Exame = (props) => {
    const { user, reset } = props;
    const navigate = useNavigate();

    const [abas, setAbas] = useState([]);
    const abaAtiva = '0';

    const resetAba = () => {
        navigate('/exames');
        reset();
    }

    useEffect(() => {
        let dadosAbas = [];
        if (user.perfisRBAC) {
            const instituicaoModalidade = [];
            user.modalidadesInstituicao.forEach(modalidade => {
                if (modalidade.sigla_modalidade === MODALIDADE.SIGLA && modalidade.relacao == RELACAO_INSTITUICAO_MODALIDADE_SERVICO) {
                    instituicaoModalidade.push(modalidade.id_instituicao);
                }
            });

            const perfisInteresse = user.perfisRBAC.filter(p => [
                PERFIL.ADMINISTRADOR,
                PERFIL.ADMINISTRADOR_ESTADUAL,
                PERFIL.MEDICO_LAUDADOR,
                PERFIL.MEDICO_SOLICITANTE,
                PERFIL.TECNICO,
                PERFIL.VISUALIZADOR,
                PERFIL.VISUALIZADOR_REDE,
                PERFIL.MEDICO_EXECUTOR
            ].includes(p.identificador));

            perfisInteresse.forEach(perfil => {
                // INSTITUIÇÃO
                if (perfil.vinculo === VINCULO_PERFIL.INSTITUICAO) {
                    perfil.instituicoes.forEach(instituicao => {
                        if (instituicaoModalidade.includes(instituicao.id)) {
                            dadosAbas.push({
                                id: instituicao.id,
                                tipo: TIPO_ABA_EXAME.INSTITUICAO,
                                titulo: instituicao.nome,
                                conteudo: Aba,
                                handleClick: resetAba
                            });
                        }
                    })
                }

                // REDE
                if (perfil.vinculo === VINCULO_PERFIL.REDE) {
                    perfil.redes.forEach(rede => {
                        const redeJaAdd = dadosAbas.some(d => (d.id === rede.id && d.tipo === TIPO_ABA_EXAME.REDE));
                        if (!redeJaAdd && user.modalidadesRede?.some(modalidade => modalidade.sigla_modalidade === MODALIDADE.SIGLA && modalidade.id_rede_telemedicina === rede.id)) {
                            dadosAbas.push({
                                id: rede.id,
                                tipo: TIPO_ABA_EXAME.REDE,
                                titulo: rede.descricao,
                                laudador: temPermissaoRede(rede, PERMISSOES.LAUDAR_EXAME_FABRICA) ? 1 : 0,
                                conteudo: Aba,
                                handleClick: resetAba
                            });
                        }
                    });
                }

                // GLOBAL
                if (perfil.vinculo === VINCULO_PERFIL.GLOBAL ||
                    perfil.identificador === PERFIL.ADMINISTRADOR_ESTADUAL ||
                    perfil.identificador === PERFIL.MEDICO_SOLICITANTE) {
                    dadosAbas.push({
                        id: perfil.identificador,
                        tipo: TIPO_ABA_EXAME.PERFIL,
                        titulo: `Perfil ${perfil.descricao}`,
                        conteudo: Aba,
                        handleClick: resetAba
                    });
                }

            });

            setAbas(dadosAbas);
        }
    }, [user]);

    return (
        <SttTabs abas={abas} abaAtiva={abaAtiva} />
    );

}

const mapStateToProps = (state) => {
    return {
        user: state.index.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        reset: () => dispatch(resetAction())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Exame);