import React from 'react';
import App from './App';
import * as serviceWorker from './serviceWorker';
import moment from 'moment';
import { createRoot } from 'react-dom/client';

moment.locale('pt-br', {
    months: 'janeiro_fevereiro_março_abril_maio_junho_julho_agosto_setembro_outubro_novembro_dezembro'.split('_'),
    monthsShort: 'jan_fev_mar_abr_mai_jun_jul_ago_set_out_nov_dez'.split('_'),
    weekdays: 'domingo_segunda-feira_terça-feira_quarta-feira_quinta-feira_sexta-feira_sábado'.split('_'),
    weekdaysShort: 'dom_seg_ter_qua_qui_sex_sáb'.split('_'),
    weekdaysMin: 'dom_2ª_3ª_4ª_5ª_6ª_sáb'.split('_'),
    longDateFormat: {
        LT: 'HH:mm',
        L: 'DD/MM/YYYY',
        LL: 'D [de] MMMM [de] YYYY',
        LLL: 'D [de] MMMM [de] YYYY [às] LT',
        LLLL: 'dddd, D [de] MMMM [de] YYYY [às] LT'
    },
    calendar: {
        sameDay: '[Hoje às] LT',
        nextDay: '[Amanhã às] LT',
        nextWeek: 'dddd [às] LT',
        lastDay: '[Ontem às] LT',
        lastWeek: function () {
            return (this.day() === 0 || this.day() === 6) ?
                '[Último] dddd [às] LT' : // Saturday + Sunday
                '[Última] dddd [às] LT'; // Monday - Friday
        },
        sameElse: 'L'
    },
    relativeTime: {
        future: 'em %s',
        past: '%s atrás',
        s: 'segundos',
        m: 'um minuto',
        mm: '%d minutos',
        h: 'uma hora',
        hh: '%d horas',
        d: 'um dia',
        dd: '%d dias',
        M: 'um mês',
        MM: '%d meses',
        y: 'um ano',
        yy: '%d anos'
    },
    ordinal: '%dº'
});

const initializeApp = (config) => {
    /* eslint-disable */
    global.gConfig = config;

    const domNode = document.getElementById('root');
    const root = createRoot(domNode);
    
    root.render(<App modulo={'ecg'} currentLanguage={'ptbr'} />);
}

const verificarBaseAlternativa = (config) => {
    let subpaths = window.location.pathname;
    if (subpaths && config.layoutsAlternativos) {
        subpaths = subpaths.split("/");

        let layoutAlternativo = null;
        subpaths.forEach((sp) => {
            if (config.layoutsAlternativos.includes(sp)) {
                layoutAlternativo = sp;
                return true;
            }
        });

        if (layoutAlternativo) {
            return fetch(
                `${process.env.PUBLIC_URL}/config/layoutsAlternativos/${layoutAlternativo}/config.stt-${config.config_id}.json`,
                {
                    headers: { "Content-Type": "application/json" },
                }
            ).then((response) => response.json());
        }
    }
    return config;
};

Promise.all([
    fetch(`${process.env.PUBLIC_URL}/config/changelog.json`).then(response => response.json()),
    fetch(`${process.env.PUBLIC_URL}/config/config.json`).then(response => response.json())
])
    .then(([changelog, config]) => Promise.all([changelog, verificarBaseAlternativa(config)]))
    .then(([changelog, config]) => initializeApp({ ...config, ...changelog }))
    .catch(error => { console.error("Erro ao buscar os arquivos de configurações do frontend", error); });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
