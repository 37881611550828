import TYPES from '../types/laudo';
const INITIAL_STATE = {
    laudar: false
};

const laudo = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case TYPES.LAUDAR:
            return {
                ...state,
                laudar: payload.laudar
            }

        default:
            return state;
    }
}

export default laudo;
