import React, { useState, useEffect, useRef, memo, lazy, Suspense, useContext } from 'react';
import { connect } from 'react-redux';
import { logoutDispatch as logoutAction } from '../../redux/actions';
import { useNavigate } from "react-router-dom";
import { Formik } from 'formik';
import { CONSTANTES_MOTIVOS_SOLICITACAO, PERMISSOES } from '../../common/Constants';
import Paciente from '@stt-componentes/paciente';
import axios from 'axios';
import { getHeaders } from '../../request';
import HttpStatus from 'http-status-codes';
import validationSchema from './validationSchema';
import {
    SttExpansionPanel,
    SttDivider,
    SttContainer,
    SttButton,
    SttLoading,
    SttAlerta,
    SttFormHelperText,
    SttCircularProgress,
    SttTranslateHook
} from '@stt-componentes/core';
import { PACIENTE } from '@stt-componentes/paciente/dist/lib/form/fieldNames';
import { initialValues } from './initialValues';
import { makeStyles } from '@material-ui/core/styles';
import { IDENTIFICACAO_EXAME, MOTIVOS_SOLICITACAO } from '../../componentes/solicitacao/identificacaoExame/form/fieldNames';
import SttCid10 from '@stt-componentes/cid10';
import { COMORBIDADES_FATORES_RISCO, FATORES_RISCO, MEDICAMENTOS_USO } from '../../componentes/solicitacao/comorbidadesFatoresRisco/fieldNames';
import { temPermissaoRBAC } from '../../security/acl';
import EnvioImagens from '../../componentes/envio-imagens/index.js';

const IdentificacaoExame = lazy(() => import('../../componentes/solicitacao/identificacaoExame'));
const Solicitante = lazy(() => import('../../componentes/solicitacao/solicitante'));
const ComorbidadesFatoresRisco = lazy(() => import('../../componentes/solicitacao/comorbidadesFatoresRisco'));
const HipoteseDiagnostica = lazy(() => import('../../componentes/solicitacao/hipoteseDiagnostica'));
const InvestigacaoDor = lazy(() => import('../../componentes/solicitacao/investigacaoDor'));

const Divider = memo((props) => {
    return (
        <SttDivider {...props} />
    )
});

const Alerta = memo((props) => {
    return (
        <SttAlerta {...props} />
    )
});

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%'
    }
}));

const campos = {
    camposBusca: {
        cpf: { obrigatorio: false },
        cns: { obrigatorio: false }
    },
    camposCadastro: [
        [
            { nome: 'nome', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            { nome: 'genero', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
            { nome: 'dataNascimento', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 3, md: 3 } },
        ],
        [
            { nome: 'cpf', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'cns', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'cep', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
        ],
        [
            { nome: 'logradouro', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 7, md: 7 } },
            { nome: 'bairro', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 5, md: 5 } },
        ],
        [
            { nome: 'pais', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 4, md: 4 } },
            { nome: 'uf', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'municipio', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
        ],
        [
            { nome: 'tipoContato1', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
            { nome: 'contato1', obrigatorio: false, somenteLeitura: false, tamanho: { xs: 12, sm: 6, md: 6 } },
        ],
        [
            { nome: 'profissao', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 8, md: 8 } },
            { nome: 'peso', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
            { nome: 'altura', obrigatorio: true, somenteLeitura: false, tamanho: { xs: 12, sm: 2, md: 2 } },
        ]
    ]
};

const Solicitacao = (props) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const { user, cpfPadrao } = props;
    const classes = useStyles();

    const ECG_API_BASE_URL = global.gConfig.url_base_eletro;
    const schema = validationSchema(strings, campos.camposCadastro);

    const handleCloseAlerta = () => {
        setMostrarAlerta(false);
    }

    const handleAbrirModalEnvioImagens = () => {
        setMostrarAlerta(false);
        setMostrarModalEnvioImagens(temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME));
    }

    const [enviarImagens, setEnviarImagens] = useState(false);
    const [mostrarModalEnvioImagens, setMostrarModalEnvioImagens] = useState(false);
    const [solicitacao, setSolicitacao] = useState(false);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [tituloAlerta, setTituloAlerta] = useState('');
    const [tipoAlerta, setTipoAlerta] = useState('alert');
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [opcoesAlerta, setOpcoesAlerta] = useState([{
        title: strings.ok,
        onClick: handleCloseAlerta
    }]);
    const [onCloseAlerta, setOnCloseAlerta] = useState(handleCloseAlerta);

    // Alerta da finalização do envio de imagens
    const [mostrarAlertaEnvioImagens, setMostrarAlertaEnvioImagens] = useState(false);
    const [tituloAlertaEnvioImagens, setTituloAlertaEnvioImagens] = useState('');
    const [tipoAlertaEnvioImagens, setTipoAlertaEnvioImagens] = useState('alert');
    const [mensagemAlertaEnvioImagens, setMensagemAlertaEnvioImagens] = useState('');
    const [opcoesAlertaEnvioImagens, setOpcoesAlertaEnvioImagens] = useState([{
        title: strings.ok,
        onClick: () => setMostrarModalEnvioImagens(false)
    }]);
    const [tratarObesidade, setTratarObesidade] = useState(null);
    const [tratarSobrepeso, setTratarSobrepeso] = useState(null);

    const secaoPaciente = useRef(null);
    const secaoSolicitante = useRef(null);
    const secaoIdentificacaoExame = useRef(null);
    const secaoComorbidadesFatoresRisco = useRef(null);
    const secaoHipoteseDiagnostica = useRef(null);
    const secaoInvestigacaoDor = useRef(null);

    const [secoesAbertas, setSecoesAbertas] = useState({
        'paciente': true,
        'solicitante': false,
        'identificacaoExame': false,
        'comorbidadesFatoresRisco': false,
        'hipoteseDiagnostica': false,
        'cid10': false,
        'investigacaoDor': false
    });

    const [confirmarTermo, setConfirmarTermo] = useState(false);
    const [gerarTermo, setGerarTermo] = useState(false);
    const [paciente, setPaciente] = useState(null);
    const [confirmarEnvioExame, setConfirmarEnvioExame] = useState(false);

    const imprimirTermo = (idPaciente) => {
        let tab = window.open();
        tab.document.write('<p>Aguarde...</p>');
        axios
            .get(`${ECG_API_BASE_URL}/termo-autorizacao/${btoa(idPaciente)}/false`, { headers: getHeaders(), responseType: 'blob' })
            .then((response) => {
                if (response.data) {
                    const fileURL = URL.createObjectURL(response.data);
                    tab.location = fileURL;
                }
            })
            .catch(err => {
                console.log(err);
                setTituloAlerta(strings.erro);
                setMensagemAlerta(strings.erroGerarTermo);
                setTipoAlerta('error');
                setOpcoesAlerta([
                    {
                        title: strings.ok,
                        onClick: () => {
                            setMostrarAlerta(false);
                        }
                    }
                ]);
                setMostrarAlerta(true);
            })
            .finally(() => {
                setPaciente(null);
                setGerarTermo(false);

            });
    }

    useEffect(() => {
        if (gerarTermo) {
            imprimirTermo(paciente);
        } else {
            setPaciente(null);
        }
    }, [gerarTermo]);

    const abrirSecao = (secao, estado) => {
        let novoSecoesAbertas = {
            ...secoesAbertas
        };
        for (const secaoAberta in novoSecoesAbertas) {
            novoSecoesAbertas[secaoAberta] = false;
        }
        novoSecoesAbertas[secao] = estado;
        setSecoesAbertas(novoSecoesAbertas);
    }

    const verificarSecoesComErro = (validateForm) => {
        validateForm().then((retorno) => {
            let node = null;

            if (retorno['paciente']) {
                abrirSecao('paciente', true);
                node = secaoPaciente.current;
            } else if (retorno['solicitante']) {
                abrirSecao('solicitante', true);
                node = secaoSolicitante.current;
            } else if (retorno['identificacaoExame']) {
                abrirSecao('identificacaoExame', true);
                node = secaoIdentificacaoExame.current;
            } else if (retorno['comorbidadesFatoresRisco']) {
                abrirSecao('comorbidadesFatoresRisco', true);
                node = secaoComorbidadesFatoresRisco.current;
            } else if (retorno['hipoteseDiagnostica']) {
                abrirSecao('hipoteseDiagnostica', true);
                node = secaoHipoteseDiagnostica.current;
            } else if (retorno['investigacaoDor']) {
                abrirSecao('investigacaoDor', true);
                node = secaoInvestigacaoDor.current;
            }

            if (node) {
                setTimeout(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'start'
                    });
                }, 500);
            }
        });
    }

    const resetarFormularioSolicitacao = (resetForm, setFieldValue) => {
        setFieldValue(`${COMORBIDADES_FATORES_RISCO}.${MEDICAMENTOS_USO}`, []);
        setFieldValue(`${COMORBIDADES_FATORES_RISCO}.${FATORES_RISCO}`, []);
        resetForm();
    }

    const enviarSolicitacao = (data, setSubmitting, resetForm, setFieldValue) => {
        let dados = JSON.parse(JSON.stringify({ ...data }));

        dados.digitador = {
            id: user.idFuncionario
        }
        if (dados.paciente) {
            if (dados.paciente.cpf) {
                dados.paciente.cpf = dados.paciente.cpf.replace(/\D+/g, '');
            }
            if (dados.paciente.cep) {
                dados.paciente.cep = dados.paciente.cep.replace(/\D+/g, '');
            }
        }

        if (dados.solicitante?.medico?.id === -1) {
            dados.solicitante.medico = dados.solicitante.outroMedico;
        }

        if (dados.paciente) {
            if (dados.paciente.profissao) {
                dados.paciente.profissao = dados.paciente.profissao.trim();
            }
        }

        if (dados.comorbidadesFatoresRisco) {
            if (dados.comorbidadesFatoresRisco.outrosMedicamentos) {
                dados.comorbidadesFatoresRisco.outrosMedicamentos = dados.comorbidadesFatoresRisco.outrosMedicamentos.trim();
            }
        }

        if (dados.hipoteseDiagnostica) {
            if (dados.hipoteseDiagnostica.cardiopatiaCongenita) {
                dados.hipoteseDiagnostica.cardiopatiaCongenita = dados.hipoteseDiagnostica.cardiopatiaCongenita.trim();
            }

            if (dados.hipoteseDiagnostica.observacoes) {
                dados.hipoteseDiagnostica.observacoes = dados.hipoteseDiagnostica.observacoes.trim();
            }

            if (dados.hipoteseDiagnostica.outros) {
                dados.hipoteseDiagnostica.outros = dados.hipoteseDiagnostica.outros.trim();
            }
        }

        let tipoAlertaSolicitacao = '';
        let tituloAlertaSolicitacao = '';
        let mensagemAlertaSolicitacao = '';

        axios
            .post(`${ECG_API_BASE_URL}/solicitacao`, dados, { headers: getHeaders() })
            .then((response) => {
                const dadosSolicitacao = response.data.data;
                const dadosPaciente = dadosSolicitacao.paciente;

                setSolicitacao({ id: dadosSolicitacao.idSolicitacao, ...dadosSolicitacao });
                setPaciente(dadosPaciente);
                abrirSecao('paciente', true);

                tipoAlertaSolicitacao = 'success';

                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () => {
                        setMostrarAlerta(false);
                        setConfirmarTermo(true);
                    }
                }]);

                setOnCloseAlerta(() => () => {
                    setMostrarAlerta(false);
                    setConfirmarTermo(true);
                });

                tituloAlertaSolicitacao = strings.sucesso;
                mensagemAlertaSolicitacao = response.data.message;

                resetarFormularioSolicitacao(resetForm, setFieldValue);
            })
            .catch(err => {
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const dadosResposta = response.data;
                        let arrMensagem = [];
                        dadosResposta.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        tipoAlertaSolicitacao = 'error';
                        tituloAlertaSolicitacao = dadosResposta.message;
                        mensagemAlertaSolicitacao = msg;
                    } else {
                        tipoAlertaSolicitacao = 'error';
                        tituloAlertaSolicitacao = strings.erro;
                        mensagemAlertaSolicitacao = msg;
                    }
                } else {
                    tipoAlertaSolicitacao = 'error';
                    tituloAlertaSolicitacao = strings.erro;
                    mensagemAlertaSolicitacao = msg;
                }
                setOpcoesAlerta([{
                    title: strings.ok,
                    onClick: () => setMostrarAlerta(false)
                }]);
            })
            .finally(() => {
                setSubmitting(false);
                setTipoAlerta(tipoAlertaSolicitacao);
                setTituloAlerta(tituloAlertaSolicitacao);
                setMensagemAlerta(mensagemAlertaSolicitacao);
                setMostrarAlerta(true);
            });
    }

    const setImc = (imc) => {
        if (!imc) {
            setTratarObesidade(false);
            setTratarSobrepeso(false);
            return;
        }
        if (imc && (parseInt(imc) >= 100 || parseInt(imc) < 10)) {
            return;
        }
        if (imc && parseInt(imc) > 29.90 && imc < 100) {
            setTratarObesidade(true);
        } else {
            setTratarObesidade(false);
        }

        if (imc && parseInt(imc) > 24.90 && imc <= 29.90) {
            setTratarSobrepeso(true);
        } else {
            setTratarSobrepeso(false);
        }
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                validateOnChange={false}
                validationSchema={schema}
                onSubmit={(data, { setFieldValue, setSubmitting, resetForm }) => {
                    setSubmitting(false);
                    setTituloAlerta(strings.confirmacao);
                    setMensagemAlerta(strings.mensagemConfirmacaoEnvioSolicitacao);
                    setTipoAlerta('alert');
                    setOpcoesAlerta([
                        {
                            title: strings.sim,
                            onClick: () => {
                                setSubmitting(true);
                                setMostrarAlerta(false);
                                enviarSolicitacao(data, setSubmitting, resetForm, setFieldValue);
                            }
                        },
                        {
                            title: strings.nao,
                            onClick: () => {
                                setMostrarAlerta(false);
                            }
                        }
                    ]);
                    setOnCloseAlerta(() => () => {
                        setMostrarAlerta(false);
                    });
                    setMostrarAlerta(true);
                }}
            >
                {
                    ({
                        values,
                        isSubmitting,
                        handleSubmit,
                        errors,
                        touched,
                        validateForm,
                        setFieldValue,
                        submitCount
                    }) => {
                        return (
                            <SttContainer>
                                <form onSubmit={handleSubmit} noValidate>
                                    <SttExpansionPanel
                                        title={strings.dadosPaciente}
                                        opened={secoesAbertas['paciente']}
                                        callback={estadoInterno => abrirSecao('paciente', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoPaciente}></div>
                                                <Paciente
                                                    strings={strings}
                                                    headers={getHeaders()}
                                                    usarTipoContato
                                                    persistirParametrosBusca
                                                    cpf-padrao={cpfPadrao}
                                                    campos={campos}
                                                    setImcExterno={setImc}
                                                    imc
                                                    formExterno={{
                                                        paciente: values[PACIENTE],
                                                        setFieldValue,
                                                        errors: errors[PACIENTE],
                                                        submitCount
                                                    }}
                                                />

                                                {
                                                    touched.paciente && errors.paciente && errors.paciente.nome &&
                                                    <SttFormHelperText error>
                                                        {strings.pacienteObrigatorio}
                                                    </SttFormHelperText>
                                                }
                                            </Suspense>
                                        }
                                    />
                                    <Divider />

                                    <SttExpansionPanel
                                        title={strings.medicoSolicitante}
                                        opened={secoesAbertas['solicitante']}
                                        callback={estadoInterno => abrirSecao('solicitante', estadoInterno)}
                                        classegriditem={classes.fullWidth}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoSolicitante}></div>
                                                <Solicitante strings={strings} />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />

                                    <SttExpansionPanel
                                        title={strings.motivoExame}
                                        opened={secoesAbertas['identificacaoExame']}
                                        classegriditem={classes.fullWidth}
                                        callback={estadoInterno => abrirSecao('identificacaoExame', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoIdentificacaoExame}></div>
                                                <IdentificacaoExame strings={strings} />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />

                                    <SttExpansionPanel
                                        title={strings.comorbidadesFatoresRisco}
                                        opened={secoesAbertas['comorbidadesFatoresRisco']}
                                        classegriditem={classes.fullWidth}
                                        callback={estadoInterno => abrirSecao('comorbidadesFatoresRisco', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoComorbidadesFatoresRisco}></div>
                                                <ComorbidadesFatoresRisco strings={strings} tratarObesidade={tratarObesidade} tratarSobrepeso={tratarSobrepeso} setTratarObesidade={setTratarObesidade} setTratarSobrepeso={setTratarSobrepeso} />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />

                                    <SttExpansionPanel
                                        title={strings.hipoteseDiagnostica}
                                        opened={secoesAbertas['hipoteseDiagnostica']}
                                        classegriditem={classes.fullWidth}
                                        callback={estadoInterno => abrirSecao('hipoteseDiagnostica', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <div ref={secaoHipoteseDiagnostica}></div>
                                                <HipoteseDiagnostica strings={strings} />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />

                                    <SttExpansionPanel
                                        title={strings.cid10}
                                        opened={secoesAbertas['cid10']}
                                        callback={estadoInterno => abrirSecao('cid10', estadoInterno)}
                                        children={
                                            <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                <SttCid10
                                                    strings={strings}
                                                    headers={getHeaders()}
                                                    multiplos
                                                    formExterno={{
                                                        cid10: values.cid10,
                                                        isSubmitting,
                                                        errors,
                                                        submitCount,
                                                        setFieldValue
                                                    }}
                                                />
                                            </Suspense>
                                        }
                                    />
                                    <Divider />

                                    {
                                        (values[IDENTIFICACAO_EXAME][MOTIVOS_SOLICITACAO] &&
                                            values[IDENTIFICACAO_EXAME][MOTIVOS_SOLICITACAO].some((motivo) => motivo.identificador == CONSTANTES_MOTIVOS_SOLICITACAO.DOR_TORACICA)) &&
                                        <>
                                            <SttExpansionPanel
                                                title={strings.investigacaoDor}
                                                opened={secoesAbertas['investigacaoDor']}
                                                callback={estadoInterno => abrirSecao('investigacaoDor', estadoInterno)}
                                                children={
                                                    <Suspense fallback={<SttCircularProgress color="primary" />}>
                                                        <div ref={secaoInvestigacaoDor}></div>
                                                        <InvestigacaoDor strings={strings} />
                                                    </Suspense>
                                                }
                                            />
                                            <Divider />
                                        </>
                                    }
                                    <br />

                                    <SttButton
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={isSubmitting}
                                        nomarginleft="true"
                                        onClick={() => verificarSecoesComErro(validateForm)}
                                    >
                                        {strings.enviar}
                                    </SttButton>
                                </form>
                                <SttLoading
                                    open={isSubmitting}
                                    text={strings.salvandoSolicitacao}
                                />
                                <Alerta
                                    open={mostrarAlerta}
                                    title={tituloAlerta}
                                    message={mensagemAlerta}
                                    type={tipoAlerta}
                                    options={opcoesAlerta}
                                    onClose={() => onCloseAlerta()}
                                />
                                <Alerta
                                    open={mostrarAlertaEnvioImagens}
                                    title={tituloAlertaEnvioImagens}
                                    message={mensagemAlertaEnvioImagens}
                                    type={tipoAlertaEnvioImagens}
                                    options={opcoesAlertaEnvioImagens}
                                />
                                <Alerta
                                    open={confirmarTermo}
                                    title={strings.termoEsclarecimento}
                                    message={strings.mensagemConfirmarTermo_solicitacao}
                                    type={'confirmation'}
                                    options={[
                                        {
                                            title: strings.sim,
                                            onClick: () => {
                                                setConfirmarTermo(false);
                                                setGerarTermo(true);
                                                if (temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
                                                    setConfirmarEnvioExame(true);
                                                }
                                            }
                                        },

                                        {
                                            title: strings.nao,
                                            onClick: () => {
                                                setConfirmarTermo(false);
                                                if (temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
                                                    setConfirmarEnvioExame(true);
                                                }
                                            }
                                        }
                                    ]}
                                    onClose={() => {
                                        setConfirmarTermo(false);
                                        if (temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME)) {
                                            setConfirmarEnvioExame(true);
                                        }
                                    }}
                                />
                                {
                                    temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) &&
                                    <Alerta
                                        open={confirmarEnvioExame}
                                        title={strings.tituloConfirmarEnvioExame}
                                        message={strings.mensagemConfirmarEnvioExame}
                                        type={'confirmation'}
                                        options={[
                                            {
                                                title: strings.sim,
                                                onClick: () => {
                                                    setEnviarImagens(true);
                                                    setConfirmarEnvioExame(false);
                                                    handleAbrirModalEnvioImagens();
                                                }
                                            },
                                            {
                                                title: strings.nao,
                                                onClick: () => {
                                                    setSolicitacao(false);
                                                    setConfirmarEnvioExame(false);
                                                }
                                            }
                                        ]}
                                        onClose={() => {
                                            setSolicitacao(false);
                                            setConfirmarEnvioExame(false);
                                        }}
                                    />
                                }
                            </SttContainer>
                        )
                    }
                }
            </Formik>
            {
                temPermissaoRBAC(user, PERMISSOES.CRIAR_EXAME) && solicitacao && enviarImagens &&
                <EnvioImagens
                    open={mostrarModalEnvioImagens}
                    resetFormulario={() => {
                        setSolicitacao(false);
                        setMostrarModalEnvioImagens(false);
                        setEnviarImagens(false);
                    }}
                    callbackFinalizarEnvio={() => {
                        setOpcoesAlertaEnvioImagens([{
                            title: strings.ok,
                            onClick: () => setMostrarAlertaEnvioImagens(false)
                        }]);
                        setTipoAlertaEnvioImagens('success');
                        setTituloAlertaEnvioImagens(strings.sucesso);
                        setMensagemAlertaEnvioImagens(strings.exameEnviadoSucesso);
                        setMostrarAlertaEnvioImagens(true);
                    }}
                    solicitacao={solicitacao}
                />
            }
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        logoutDispatch: () => dispatch(logoutAction())
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.index.user,
        idSistemaOrigem: state.index.idSistemaOrigem,
        cpfPadrao: state.index.cpfPaciente
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Solicitacao);